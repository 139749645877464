import React from 'react';
import StarterPrompt from './StarterPrompt';
import SearchIcon from 'remixicon-react/Search2LineIcon';
import { StarterPrompt as StarterPromptType } from '../../../api/generated';
import { TEST_ID_STARTER_PROMPTS } from '../../../constants';
import ActionButton from '../../base/ActionButton';

interface StarterPromptsProps {
  starterPrompts?: StarterPromptType[];
  submitStarterPrompt: (starterPrompt: string) => void;
  showExploreTemplates?: boolean;
}

const StarterPrompts = (props: StarterPromptsProps) => {
  if (!props.starterPrompts) {
    return null;
  }

  return (
    <div
      className="flex flex-col items-start gap-2 py-6 px-4"
      data-testid={TEST_ID_STARTER_PROMPTS}
    >
      {props.showExploreTemplates && (
        <ActionButton
          buttonType="primary"
          fillState="subtle"
          className="w-full !justify-start dark:hover:bg-dark-tertiary h-11"
          onClick={() => window.open('https://www.getlazy.ai/explore', '_blank')}
        >
          <SearchIcon size={20} />
          Explore templates
        </ActionButton>
      )}
      {props.starterPrompts.map((starterPrompt, index) => (
        <StarterPrompt
          key={index}
          starterPrompt={starterPrompt}
          submitStarterPrompt={props.submitStarterPrompt}
        />
      ))}
    </div>
  );
};

export default StarterPrompts;
