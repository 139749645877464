import React from 'react';
import { AppMemoryOptions, AppMemoryOptionsProps } from './AppMemoryOptions';
import { AutoUpdateTestApp } from './AutoUpdateTestAppSwitch';
import Button from './Button';
import classNames from 'classnames';
import * as dataTestidConstants from '../../constants';
import { useAppStore } from '../../store/app';

export interface RunAppButtonDropdownContentProps {
  appMemoryOptionsProps: AppMemoryOptionsProps;
  leftAlignDropDown?: boolean;
  isTestingApp?: boolean;
  handleSaveInstanceSettings: () => void;
}
export const RunAppButtonDropdownContent = (props: RunAppButtonDropdownContentProps) => {
  const currentRam = useAppStore.getState().currentAppRam;
  return (
    <div className="dark:bg-dark-tertiary dark:text-dark-label-primary">
      {props.isTestingApp && process.env.REACT_APP_ENABLE_AUTOMATIC_TEST_APP_UPDATE === 'true' && (
        <AutoUpdateTestApp />
      )}
      <AppMemoryOptions currentRam={currentRam} />
      <div className="flex justify-center w-full">
        <Button
          className={classNames(
            'bg-system-success text-white mt-[10px] hover:bg-system-success/80 gap-1'
          )}
          dataTestid={dataTestidConstants.INSTANCE_SETTINGS_SAVE_BUTTON}
          onClick={props.handleSaveInstanceSettings}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
