/* eslint-disable  */
import React, {
  ChangeEvent,
  FC,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import DeleteBin7Line from 'remixicon-react/DeleteBin6LineIcon';
import Edit2Line from 'remixicon-react/Edit2LineIcon';
import classNames from 'classnames';
import Button from '../base/Button';
import { Input } from '../base/Input';
import { Secret } from '../../types/secret';
import { Switch } from '../base/Switch/Switch';
import * as dataTestidConstants from '../../constants';
import { ApproveModal } from '../../components/base/ApproveModal';
import { useChatStore } from '../../store/chat';
import {
  DEFAULT_VALUE_FOR_AUTOMATICALLY_GENERATED_SECRETS,
  SECRET_VALUE_NEEDED_MESSAGE,
} from '../../constants';
import QuestionFill from 'remixicon-react/QuestionFillIcon';
import { isMobileDevice } from '../../utils/deviceDimensions';
interface SecretTileProps {
  isEditMode: boolean;
  isNew?: boolean;
  secret?: Secret;
  askLazyAboutSecret?: (name: string) => Promise<void>;
  onEdit?: () => void;
  onSave?: (secret: Secret) => void;
  onCancel?: () => void;
  onRemove?: () => void;
}

const SECRETS_MAX_LENGTH = 4096;
const SECRET_AUTO_GENERATED_COMMENT = 'Auto-generated secret. Needs value definition';

const generateAsteriskString = () => new Array(16).fill('*').join();

export const getValueToBeDisplayedForSecret = (secretValue?: string) => {
  if (
    !secretValue ||
    secretValue === DEFAULT_VALUE_FOR_AUTOMATICALLY_GENERATED_SECRETS ||
    secretValue === SECRET_VALUE_NEEDED_MESSAGE
  ) {
    return SECRET_VALUE_NEEDED_MESSAGE;
  } else {
    return generateAsteriskString().replace(/,/g, '*');
  }
};

export const SecretTile = forwardRef((props: SecretTileProps, ref) => {
  const { secret, isEditMode, isNew, onEdit, askLazyAboutSecret, onCancel, onSave, onRemove } =
    props;
  const [secretState, setSecret] = useState<Secret>(secret || ({} as Secret));
  const { userInputLoading } = useChatStore();
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
  const [isEmptySecret, setIsEmptySecret] = useState<boolean>(false);

  const { name, value, test_value, comment, use_environment_specific_values } = secretState;

  const hasUnsavedChanges = () => {
    return (
      secretState.name !== secret?.name ||
      secretState.comment !== secret?.comment ||
      (secretState.value !== secret?.value && secretState.value.trim() != '') ||
      (secretState.test_value !== secret?.test_value && secretState.test_value?.trim() != '')
    );
  };

  const [testValue, setTestValue] = useState<string>(
    isNew ? '' : getValueToBeDisplayedForSecret(test_value)
  );
  const [prodValue, setProdValue] = useState<string>(
    isNew ? '' : getValueToBeDisplayedForSecret(value)
  );

  const isShowTestValue = use_environment_specific_values;

  useEffect(() => {
    if (secret) {
      setSecret(secret);
    }
  }, [secret]);

  useEffect(() => {
    if (
      getValueToBeDisplayedForSecret(secret?.test_value) === SECRET_VALUE_NEEDED_MESSAGE &&
      getValueToBeDisplayedForSecret(secret?.value) === SECRET_VALUE_NEEDED_MESSAGE
    ) {
      setIsEmptySecret(true);
    } else {
      setIsEmptySecret(false);
    }
  }, [secret]);

  useEffect(() => {
    if (!isNew && secret) {
      setSecret({
        ...secret,
        value: secret.value ? secret.value : generateAsteriskString(),
        test_value:
          secret.test_value ||
          (secret.use_environment_specific_values ? generateAsteriskString() : secret.test_value),
      });
    }
  }, [isNew, secret]);

  const handleChange = (key: keyof Secret) => (e: ChangeEvent<HTMLInputElement>) => {
    setSecret((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
    if (key === 'test_value') {
      setTestValue(e.target.value);
    } else if (key === 'value') {
      setProdValue(e.target.value);
    }
  };

  const handleDifferentValueChecked = (value: boolean) => {
    setSecret((prev) => ({
      ...prev,
      use_environment_specific_values: value,
    }));
  };

  const handleSavingNewSecret = () => {
    onSave &&
      onSave(
        use_environment_specific_values
          ? secretState
          : {
              ...secretState,
              test_value: secretState.value,
            }
      );
    handleReset();
  };

  const handleSavingExistingSecret = () => {
    onSave &&
      onSave({
        ...secretState,
        ...(secretState.comment === SECRET_AUTO_GENERATED_COMMENT ? { comment: '' } : {}),
      });
    setTestValue(getValueToBeDisplayedForSecret(test_value));
    setProdValue(getValueToBeDisplayedForSecret(value));
  };

  const handleReset = () => {
    setSecret({} as Secret);
    setProdValue('');
    setTestValue('');
  };

  const discardUnsavedChanges = () => {
    setTestValue(getValueToBeDisplayedForSecret(secret?.test_value));
    setProdValue(getValueToBeDisplayedForSecret(secret?.value));
    secret && setSecret(secret);
  };

  const handleAskLazyAboutSecret = () => {
    if (askLazyAboutSecret && secret?.name) {
      askLazyAboutSecret(secret?.name);
    }
  };

  const getEditButtonStyle = () => {
    return `bg-transparent ${isEmptySecret ? 'text-system-green-7' : ''}`;
  };
  const renderNewSecretActionButtons = () => (
    <>
      <Button className="text-system-accent bg-transparent min-w-[95px]" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        className="bg-system-accent text-white min-w-[95px]"
        onClick={handleSavingNewSecret}
        disabled={!name || userInputLoading}
      >
        Save
      </Button>
    </>
  );

  const renderEditSecretActionButtons = () => (
    <>
      <Button
        className="bg-transparent"
        onClick={() => {
          onCancel && onCancel();
          discardUnsavedChanges();
        }}
      >
        Cancel
      </Button>
      <Button
        disabled={userInputLoading}
        className=" bg-system-success text-white"
        onClick={handleSavingExistingSecret}
      >
        Save changes
      </Button>
    </>
  );

  const renderSecretViewModeActionButtons = () => (
    <>
      <Button
        disabled={userInputLoading}
        className={getEditButtonStyle()}
        iconProps={{ icon: Edit2Line }}
        onClick={onEdit}
        dataTestid={dataTestidConstants.ENV_SECRETS_EDIT_A_SECRET_BUTTON}
      >
        {isEmptySecret ? 'Add' : 'Edit'}
      </Button>
      <Button
        disabled={userInputLoading}
        className="bg-transparent text-system-danger"
        iconProps={{ icon: DeleteBin7Line }}
        onClick={() => setIsApprovalModalOpen(true)}
        dataTestid={dataTestidConstants.ENV_SECRETS_DELETE_A_SECRET_BUTTON}
      >
        Remove
      </Button>
    </>
  );

  const renderSecretNameInput = () => (
    <div className="flex justify-between items-center">
      <Input
        disabled={!isNew}
        isEditMode={isEditMode}
        label={
          <div className="flex gap-1">
            <span className={classNames({ 'hidden lg:flex': !isNew })}>Secret</span>
            <span>Name</span>:
          </div>
        }
        value={name}
        placeholder="Name"
        onChange={handleChange('name')}
        containerClassName={classNames({ 'grid-cols-[100px_1fr]': isNew })}
        dataTestid={dataTestidConstants.ENV_SECRETS_SECRET_NAME_EDIT}
      />

      {!isNew && (
        <Button
          disabled={userInputLoading}
          className="bg-transparent underline text-system-blue-6 w-40"
          onClick={handleAskLazyAboutSecret}
          dataTestid={dataTestidConstants.ENV_SECRETS_ASK_LAZY_ABOUT_ME_BUTTON}
          iconProps={{ icon: QuestionFill, iconSize: 16 }}
        >
          {isMobileDevice() ? '' : 'What is this?'}
        </Button>
      )}
    </div>
  );

  const renderSecretDescriptionInput = () => (
    <Input
      isEditMode={isEditMode}
      label="Description:"
      value={comment}
      placeholder="Add description"
      onChange={handleChange('comment')}
      containerClassName={classNames({
        'grid-cols-[100px_1fr]': isNew,
        hidden:
          !isEditMode &&
          !(
            prodValue === SECRET_VALUE_NEEDED_MESSAGE && testValue === SECRET_VALUE_NEEDED_MESSAGE
          ) &&
          comment === SECRET_AUTO_GENERATED_COMMENT,
      })}
      dataTestid={dataTestidConstants.ENV_SECRETS_DESCRIPTION_EDIT}
    />
  );

  const renderSecretTestValueInput = () => (
    <Input
      maxLength={SECRETS_MAX_LENGTH}
      isEditMode={isEditMode}
      label={
        <div className="flex gap-1">
          Test<span className={classNames({ 'hidden lg:flex': !isNew })}>Value</span>:
        </div>
      }
      value={testValue}
      placeholder="Add value"
      type={isNew || isEditMode || testValue === SECRET_VALUE_NEEDED_MESSAGE ? 'text' : 'password'}
      onChange={handleChange('test_value')}
      onFocus={(e) => {
        if (!isNew && isEditMode) {
          if (testValue === getValueToBeDisplayedForSecret(testValue)) {
            setTestValue('');
          }
        }
      }}
      containerClassName={classNames({
        'grid-cols-[100px_1fr]': isNew,
        'text-system-danger': testValue === SECRET_VALUE_NEEDED_MESSAGE,
      })}
      dataTestid={dataTestidConstants.ENV_SECRETS_TEST_VALUE_EDIT}
    />
  );

  const renderSecretValueInput = () => (
    <Input
      maxLength={SECRETS_MAX_LENGTH}
      isEditMode={isEditMode}
      label={
        <div className="flex gap-1">
          <span
            className={classNames({
              hidden: !isShowTestValue,
            })}
          >
            Production
          </span>
          <span className={classNames({ 'hidden lg:flex': !isNew && isShowTestValue })}>Value</span>
          :
        </div>
      }
      value={prodValue}
      placeholder="Add value"
      type={isNew || isEditMode || prodValue === SECRET_VALUE_NEEDED_MESSAGE ? 'text' : 'password'}
      onChange={handleChange('value')}
      onFocus={(e) => {
        if (!isNew && isEditMode) {
          if (prodValue === getValueToBeDisplayedForSecret(prodValue)) {
            setProdValue('');
          }
        }
      }}
      containerClassName={classNames({
        'grid-cols-[100px_1fr]': isNew,
        'text-system-danger': prodValue === SECRET_VALUE_NEEDED_MESSAGE,
      })}
      dataTestid={dataTestidConstants.ENV_SECRETS_PROD_VALUE_EDIT}
    />
  );

  const renderSecretRemovalConfirmationModal = () => (
    <ApproveModal
      message={
        <span className="whitespace-pre-line">
          Are you sure you want to remove
          <br />
          the {name} secret?
        </span>
      }
      approveButton={{
        text: 'Remove',
        className: 'bg-system-danger text-white',
      }}
      rejectButton={{
        className: 'bg-background-secondary dark:bg-dark-tertiary',
        text: "Don't  remove",
      }}
      onApprove={() => {
        onRemove && onRemove();
        setIsApprovalModalOpen(false);
      }}
      onReject={() => {
        setIsApprovalModalOpen(false);
      }}
    />
  );

  const renderSecretTestValueToggle = () => (
    <Switch
      name={name}
      label="Use different values for testing & production"
      className="max-w-[190px] lg:max-w-none lg:pl-[112px]"
      checked={use_environment_specific_values}
      onChange={handleDifferentValueChecked}
      dataTestid={dataTestidConstants.ENV_SECRETS_DIFFERENT_VALUES_SWITCH}
    />
  );

  useImperativeHandle(ref, () => ({
    hasUnsavedChanges,
    discardUnsavedChanges,
  }));

  return (
    <div className="grid rounded-xl dark:bg-dark-secondary bg-neutral-50">
      {isApprovalModalOpen && renderSecretRemovalConfirmationModal()}
      <div
        className={classNames('grid gap-6', {
          'p-5': isNew,
          'p-4': !isNew,
        })}
      >
        {isNew && <span className="text-base font-medium">Add new environment secret</span>}

        <div
          className={classNames('grid', {
            'gap-1': !isNew,
            'gap-3': isNew,
          })}
        >
          {renderSecretNameInput()}
          {isShowTestValue && renderSecretTestValueInput()}
          {renderSecretValueInput()}
          {renderSecretDescriptionInput()}
        </div>
        {isEditMode && renderSecretTestValueToggle()}
      </div>
      {isNew && <hr className="dark:border-dark-system-separator border-system-separator" />}
      <div
        className={classNames('flex justify-end gap-2 p-4', {
          'pt-0': !isNew,
        })}
      >
        {isNew
          ? renderNewSecretActionButtons()
          : isEditMode
          ? renderEditSecretActionButtons()
          : renderSecretViewModeActionButtons()}
      </div>
    </div>
  );
});
