import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import LazyLightTheme from './monaco-themes/lazy-light.json';
import DiffEditor from 'react-monaco-editor/lib/diff';
import { editor } from 'monaco-editor';

import IStandaloneCodeDiffEditor = editor.IStandaloneDiffEditor;
import IStandaloneCodeEditor = editor.IStandaloneCodeEditor;
import IStandaloneDiffEditorConstructionOptions = editor.IStandaloneDiffEditorConstructionOptions;
import BuiltinTheme = editor.BuiltinTheme;
import Button from './base/Button';
import classNames from 'classnames';
import BasicModal from './BasicModal';
import CodeEditIcon from 'remixicon-react/PencilLineIcon';
import { useChatStore } from '../store/chat';
import Editor from 'react-monaco-editor/lib/editor';
import { File, FileFormat } from '../api/generated';
import { Switch } from './base/Switch/Switch';
import { isMobileDevice } from '../utils/deviceDimensions';
import { TEST_ID_CODE_LISTING_FILES } from '../constants';

export type ExtendedFile = File & {
  hasChanges: boolean;
  textIcon?: string;
};

export const EMPTY_FILE: ExtendedFile = {
  name: '',
  path: '',
  content: '',
  format: FileFormat.PYTHON,
  hasChanges: false,
};

interface CodeListingProps {
  files?: ExtendedFile[];
  modifiedFiles?: ExtendedFile[];
  updateFile: (oldFile: ExtendedFile, newFile: ExtendedFile) => Promise<void>;
  grabFocus: (holdingFocus: boolean) => void;
}

// eslint-disable-next-line max-lines-per-function, max-statements
const CodeListing: React.FC<CodeListingProps> = ({
  files,
  modifiedFiles,
  updateFile,
  grabFocus,
}) => {
  const divContainer = useRef<HTMLDivElement>(null);
  const [codeEditorRef, setCodeEditorRef] = useState<IStandaloneCodeEditor | null>(null);

  const [codeDiffRef, setCodeDiffRef] = useState<IStandaloneCodeDiffEditor | null>(null);

  const [diffEditorOptions, setDiffEditorOptions] =
    useState<IStandaloneDiffEditorConstructionOptions>({
      autoIndent: 'full',
      contextmenu: true,
      fontFamily: 'DM Mono',
      fontSize: 13,
      lineHeight: 24,
      hideCursorInOverviewRuler: true,
      matchBrackets: 'always',
      minimap: {
        enabled: false,
      },
      padding: {
        top: 0,
        bottom: 0,
      },
      scrollbar: {
        horizontalSliderSize: 0,
        verticalScrollbarSize: 0,
        verticalSliderSize: 0,
        horizontal: 'hidden',
        vertical: 'hidden',
        useShadows: false,
      },
      selectOnLineNumbers: true,
      roundedSelection: false,
      readOnly: true,
      cursorStyle: 'line',
      theme: 'lazy-light',
      // We can set it to True once we upgrade to monaco version having following fix
      // https://github.com/microsoft/monaco-editor/issues/4311
      automaticLayout: false,
      renderSideBySide: false,
      originalEditable: false,
      glyphMargin: false,
      folding: false,
      lineDecorationsWidth: 20,
      lineNumbersMinChars: 0,
    });

  const editorWidth = '100%';
  const editorHeight = '100%';
  const [cancelChangesModalOpen, setCancelChangesModalOpen] = useState(false);
  const [currentOpenFile, setCurrentOpenFile] = useState<ExtendedFile>(
    files ? files[0] : EMPTY_FILE
  );

  const [viewCodeDiffs, setViewCodeDiffs] = useState<boolean>(false);
  const [isEditingCode, setIsEditingCode] = useState<boolean>(false);
  const [isCodeDiffSwitchAvailable, setIsCodeDiffSwitchAvailable] = useState<boolean>(false);
  const [currentOriginalCode, setCurrentOriginalCode] = useState<string>('');

  const lightTheme = {
    ...LazyLightTheme,
    base: 'vs' as BuiltinTheme,
  };

  editor.defineTheme('lazy-light', lightTheme);
  editor.setTheme('lazy-light');

  const getFileFullPath = (file: ExtendedFile) => `${file?.path ?? ''}${file?.name ?? ''}`;

  // Refresh the editor when files are changed (e.g. View Version)
  useEffect(() => {
    // Do not change the current file unless necessary.
    const current = (files ?? []).find(
      (file) => getFileFullPath(currentOpenFile) === getFileFullPath(file)
    );
    if (!current) {
      setCurrentOpenFile(files ? files[0] : EMPTY_FILE);
    } else {
      // This is necessary to re-render the editor when the file content is changed.
      setCurrentOpenFile(current);
    }
  }, [files, modifiedFiles]);

  // Avoid issues with misplaced cursors by re-measuring fonts.
  // This is necessary since we use custom fonts.
  useEffect(() => {
    // eslint-disable-next-line no-void, promise/always-return
    void document.fonts.ready.then(() => {
      editor.remeasureFonts();
    });
  }, []);

  const getOriginalFile = (): string => {
    return currentOpenFile?.content ?? '';
  };

  const getFileInEditor = (): string => {
    const value = codeDiffRef
      ? codeDiffRef.getModifiedEditor().getValue()
      : codeEditorRef?.getValue();
    if (value === undefined) {
      // This will only happen if the editor is not yet ready, and there have been no
      // changes to the contents yet.
      return getOriginalFile();
    }
    return value;
  };

  const isFileChangedInEditor = (): boolean => {
    return getOriginalFile() !== getFileInEditor();
  };

  const revertToSavedCode = () => {
    const savedCode = getOriginalFile();
    codeDiffRef
      ? codeDiffRef?.getModifiedEditor().setValue(savedCode)
      : codeEditorRef?.setValue(savedCode);
    setDiffEditorOptions({ ...diffEditorOptions, readOnly: true });
  };

  const saveCode = () => {
    const value = codeEditorRef
      ? codeEditorRef.getValue()
      : codeDiffRef?.getModifiedEditor().getValue();

    const currentOpenedFiles = isCodeDiffSwitchAvailable ? modifiedFiles : files;

    const currentOriginalOpenedFile = currentOpenedFiles?.find(
      (file) => file.name === currentOpenFile.name
    );
    if (currentOriginalOpenedFile) {
      setDiffEditorOptions({ ...diffEditorOptions, readOnly: true });

      const updatePromise: Promise<void> = updateFile(currentOriginalOpenedFile, {
        ...currentOriginalOpenedFile,
        content: value || '',
      });

      updatePromise.catch(() => setDiffEditorOptions({ ...diffEditorOptions, readOnly: false }));
    }
    setIsEditingCode(false);
  };

  const onChangeViewCodeDiffs = () => {
    setViewCodeDiffs(!viewCodeDiffs);
  };

  const updateHeight = () => {
    if (divContainer.current) {
      const contentHeight = Math.min(
        divContainer.current.clientHeight,
        codeDiffRef
          ? codeDiffRef.getOriginalEditor().getContentHeight()
          : codeEditorRef
          ? codeEditorRef.getContentHeight()
          : 0
      );
      divContainer.current.style.width = editorWidth;
      divContainer.current.style.minHeight = `${contentHeight}px`;
    }
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault();
        saveCode();
      }
    },
    [saveCode]
  );

  const getModifiedFile = (): string => {
    let result = currentOpenFile?.content ?? '';
    if (modifiedFiles) {
      const modifiedFile = modifiedFiles.find(
        (file: ExtendedFile) => getFileFullPath(file) === getFileFullPath(currentOpenFile)
      );
      result = modifiedFile?.content ?? result;
    }
    return result;
  };

  const getFilesOptions = (): ExtendedFile[] => {
    const filesList = modifiedFiles && modifiedFiles.length ? modifiedFiles : files || [];

    filesList.forEach((file) => {
      const originalFile = files?.find(
        (originalFile) => originalFile.name === file.name && originalFile.path === file.path
      );
      if (originalFile) {
        file.hasChanges = file.content !== originalFile.content;
        file.textIcon = file.content !== originalFile.content ? '🟢 (changed)' : '';
      } else {
        file.hasChanges = true;
        file.textIcon = '🟢 (new)';
      }
    });

    const sortedFiles = filesList.sort((fileA, fileB) => {
      if (fileA.hasChanges && !fileB.hasChanges) {
        return -1;
      } else if (!fileA.hasChanges && fileB.hasChanges) {
        return 1;
      } else {
        return 0;
      }
    });

    return sortedFiles;
  };

  const getDisplayFileName = (file: ExtendedFile): string => {
    return `${file?.path ?? ''}${file?.name ?? ''}`;
  };

  const createOptions = () => {
    const options = getFilesOptions();
    return options.map((file: ExtendedFile) => (
      <option key={getDisplayFileName(file)} value={getDisplayFileName(file)}>
        {getDisplayFileName(file)} {file.textIcon}
      </option>
    ));
  };

  const onChangeOpenFile = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedFile = files?.find(
      (file: ExtendedFile) => getFileFullPath(file) === e.target.value
    );

    const selectedModifiedFile = modifiedFiles?.find(
      (file: ExtendedFile) => getFileFullPath(file) === e.target.value
    );
    if (selectedFile) {
      setCurrentOpenFile(selectedFile);
    } else if (selectedModifiedFile) {
      setCurrentOpenFile(selectedModifiedFile);
    } else {
      setCurrentOpenFile(EMPTY_FILE);
    }
  };

  useEffect(() => {
    updateHeight();
    if (codeEditorRef || codeDiffRef) {
      const domNode = codeDiffRef
        ? codeDiffRef?.getOriginalEditor().getDomNode()
        : codeEditorRef?.getDomNode();
      if (domNode) {
        domNode.addEventListener('keydown', handleKeyDown);
        // Return a cleanup function to remove the event listener
        return () => {
          domNode.removeEventListener('keydown', handleKeyDown);
        };
      }
    }
  }, [codeEditorRef, codeDiffRef, handleKeyDown]);

  useEffect(() => {
    if (modifiedFiles) {
      setIsCodeDiffSwitchAvailable(modifiedFiles && modifiedFiles.length > 0 && !isEditingCode);
    }
  }, [modifiedFiles, isEditingCode]);

  useEffect(() => {
    if (codeEditorRef || codeDiffRef) {
      codeEditorRef
        ? codeEditorRef.updateOptions(diffEditorOptions)
        : codeDiffRef?.updateOptions(diffEditorOptions);
    }
  }, [diffEditorOptions, codeEditorRef, codeDiffRef]);

  useEffect(() => {
    if (diffEditorOptions.readOnly) {
      if (codeDiffRef) {
        codeDiffRef.getOriginalEditor().setValue(currentOpenFile.content || '');
      } else {
        codeEditorRef?.setValue(currentOpenFile.content || '');
      }
    }
  }, [files, currentOpenFile]);

  const userInputLoading = useChatStore.getState().userInputLoading;

  useEffect(() => {
    if (userInputLoading) {
      setDiffEditorOptions({ ...diffEditorOptions, readOnly: true });
      grabFocus(false);
    }
  }, [userInputLoading]);

  useEffect(() => {
    setTimeout(() => {
      setCurrentOriginalCode(viewCodeDiffs ? getOriginalFile() : getModifiedFile());
    }, 150);
  }, [viewCodeDiffs]);

  useEffect(() => {
    if (files?.length && modifiedFiles?.length) {
      setViewCodeDiffs(true);
    }
  }, [files, modifiedFiles]);

  return (
    <div ref={divContainer} className="flex flex-col grow h-full">
      <div
        className={
          'flex flex-row items-center border-b border-system-separator px-4 h-[57px] gap-2'
        }
      >
        <div className="flex-1">
          <select
            data-testid={TEST_ID_CODE_LISTING_FILES}
            onChange={onChangeOpenFile}
            disabled={!diffEditorOptions.readOnly}
            value={getFileFullPath(currentOpenFile)}
          >
            {createOptions()}
          </select>
        </div>

        <div className="flex flex-row gap-2">
          {isCodeDiffSwitchAvailable && (
            <div className="flex items-center flex-end">
              <Switch
                name="code-diffs"
                label={isMobileDevice() ? 'Diff' : 'Compare to previous'}
                className="max-w-[190px] lg:max-w-none"
                checked={viewCodeDiffs}
                onChange={onChangeViewCodeDiffs}
              ></Switch>
            </div>
          )}
          <div className={classNames('flex gap-2')}>
            <Button
              disabled={userInputLoading}
              iconProps={{ icon: CodeEditIcon, iconSize: 20 }}
              className={classNames(
                'bg-system-blue-6/10 hover:bg-system-blue-6/20 text-system-blue-6',
                {
                  hidden: !diffEditorOptions.readOnly || useChatStore.getState().isViewingVersion,
                }
              )}
              onClick={() => {
                setDiffEditorOptions({ ...diffEditorOptions, readOnly: false });
                grabFocus(true);
                setIsEditingCode(true);
              }}
            >
              {isMobileDevice() ? 'Edit' : 'Edit Code'}
            </Button>
          </div>

          <div className={classNames('flex gap-2', { hidden: diffEditorOptions.readOnly })}>
            <Button onClick={saveCode} className="bg-system-green-7 text-white">
              Save
            </Button>
            <Button
              onClick={() => {
                const modified = isFileChangedInEditor();
                if (modified) {
                  setCancelChangesModalOpen(true);
                } else {
                  revertToSavedCode();
                  setIsEditingCode(false);
                }
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
      {cancelChangesModalOpen ? (
        <BasicModal onHide={() => setCancelChangesModalOpen(false)}>
          <div className="flex flex-col items-center justify-center px-2 py-5">
            <div className="w-full md:w-1/2 text-center">
              Are you sure you don't want to save the changes?
            </div>
            <div className="p-3 w-full md:w-1/2 flex flex-col gap-3">
              <Button
                className="bg-system-green-7 text-white"
                onClick={() => {
                  setCancelChangesModalOpen(false);
                  saveCode();
                }}
              >
                Save changes
              </Button>
              <Button
                className="bg-background-secondary dark:bg-dark-tertiary"
                onClick={() => {
                  setCancelChangesModalOpen(false);
                  revertToSavedCode();
                  setIsEditingCode(false);
                }}
              >
                Don't save
              </Button>
            </div>
          </div>
        </BasicModal>
      ) : null}
      {process.env.REACT_APP_FLAG_ENABLE_CODE_DIFF === 'true' &&
      !isEditingCode &&
      viewCodeDiffs &&
      currentOriginalCode ? (
        <DiffEditor
          language={currentOpenFile?.format ?? ''}
          original={getOriginalFile()}
          value={getModifiedFile()}
          options={diffEditorOptions}
          editorDidMount={setCodeDiffRef}
          height={editorHeight}
          width={editorWidth}
        />
      ) : (
        <Editor
          language={currentOpenFile?.format ?? ''}
          value={getModifiedFile() ?? ''}
          options={diffEditorOptions}
          editorDidMount={setCodeEditorRef}
          height={editorHeight}
          width={editorWidth}
        />
      )}
    </div>
  );
};

export default CodeListing;
