/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum LLMType {
    BEST = 'best',
    CHEAP = 'cheap',
    DUMMY = 'dummy',
    GPT_4_32K = 'gpt-4-32k',
    GPT_4_JUNE = 'gpt-4-june',
    GPT_3_5_TURBO = 'gpt-3.5-turbo',
    GPT_3_5_TURBO_16K = 'gpt-3.5-turbo-16k',
    GPT_4_1106_PREVIEW = 'gpt-4-1106-preview',
    GPT_4_0125_PREVIEW = 'gpt-4-0125-preview',
    GPT_4O_2024_05_13 = 'gpt-4o-2024-05-13',
    GPT_4O_2024_08_06 = 'gpt-4o-2024-08-06',
    GPT_4O_2024_11_20 = 'gpt-4o-2024-11-20',
    GPT_4O_MINI = 'gpt-4o-mini',
    GPT_4O_MINI_2024_07_18 = 'gpt-4o-mini-2024-07-18',
    O1_PREVIEW_2024_09_12 = 'o1-preview-2024-09-12',
    O1_MINI_2024_09_12 = 'o1-mini-2024-09-12',
    O3_MINI_2025_01_31_LOW = 'o3-mini-2025-01-31-low',
    O3_MINI_2025_01_31_MEDIUM = 'o3-mini-2025-01-31-medium',
    O3_MINI_2025_01_31_HIGH = 'o3-mini-2025-01-31-high',
    CLAUDE_3_5_SONNET_20240620 = 'claude-3-5-sonnet-20240620',
    CLAUDE_3_5_SONNET_20241022 = 'claude-3.5-sonnet-20241022',
    CLAUDE_3_7_SONNET_20250219 = 'claude-3-7-sonnet-20250219',
    CLAUDE_3_5_HAIKU_20241022 = 'claude-3.5-haiku-20241022',
    CLAUDE_OPUS = 'claude-opus',
    CLAUDE_HAIKU = 'claude-haiku',
    GEMINI_1_5_PRO_EXP_0801 = 'gemini-1.5-pro-exp-0801',
    GEMINI_1_5_FLASH = 'gemini-1.5-flash',
    GEMINI_2_0_FLASH_EXP = 'gemini-2.0-flash-exp',
    DEEPSEEK_REASONER = 'deepseek-reasoner',
    DEEPSEEK_CHAT = 'deepseek-chat',
}
