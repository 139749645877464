import classNames from 'classnames';
import React, { Ref, useEffect } from 'react';
import EditIcon from 'remixicon-react/EditLineIcon';

import useModalVisibility from '../../../../hooks/modalVisibility';
import { isMobileDevice } from '../../../../utils/deviceDimensions';
import { RichPromptAction } from '../../../../components/chat/RichPromptActionList';

import MenuLabelItem from '../../..//base/MenuLabelItem';

interface ActionsMenuProps {
  onEditFolderName: () => void;
  onClose: () => void;
}

// eslint-disable-next-line max-lines-per-function
export const ActionsMenu = ({ onEditFolderName, onClose }: ActionsMenuProps) => {
  const { itemRef, isVisible } = useModalVisibility(true);

  useEffect(() => {
    if (!isVisible) {
      onClose();
    }
  }, [isVisible]);

  const actions = [
    {
      content: (
        <MenuLabelItem
          label="Rename"
          iconProps={{ icon: EditIcon, iconSize: 18 }}
          className=""
        ></MenuLabelItem>
      ),
      onClick: onEditFolderName,
    },
  ];

  const handleActionClick = (action: RichPromptAction) => {
    action.onClick();
  };

  return (
    <div
      className={classNames(
        'flex flex-col bg-white rounded',
        'z-[5] absolute top-0 right-0',
        {
          'gap-[2px]': !isMobileDevice(),
          'gap-[4px]': isMobileDevice(),
        },
        'dark:bg-dark-background-input dark:text-dark-label-primary'
      )}
      ref={itemRef as Ref<HTMLDivElement>}
    >
      {actions.map((action, index) => (
        <button
          key={index}
          className="flex py-1.5 rounded-md
            text-label-primary hover:bg-system-success-hover
            dark:text-dark-label-primary dark:hover:bg-dark-tertiary dark:rounded-none"
          onClick={() => handleActionClick(action)}
          onMouseDown={(e) => e.preventDefault()}
        >
          {action.content}
        </button>
      ))}
    </div>
  );
};
