import EditIcon from 'remixicon-react/PencilFillIcon';
import { ReactComponent as SparkingIcon } from '../../assets/sparkling.svg';
import React, { useState, useRef, useEffect } from 'react';
import { useChatStore } from '../../store/chat';
import classNames from 'classnames';
import { isMobileDevice } from '../../utils/deviceDimensions';
import { EDIT_MESSAGE_BUTTON, SOUNDS_GOOD_LETS_BUILD_IT_PROMPT } from '../../constants';
import { BuilderInteractionMode } from '../../api/generated/models/BuilderInteractionMode';
import { BaseLLMSpec } from '../../api/generated/models/BaseLLMSpec';
import { Provider } from '../../api/generated/models/Provider';
import { LLMType } from '../../api/generated/models/LLMType';
import ActionButton from '../base/ActionButton';

interface EditMessageButtonProps {
  previousMessageBuilderSessionStateId?: string;
  messageContent: string;
  messagePrefix?: string;
  currentMessageBuilderSessionStateId?: string;
}

export const Models: BaseLLMSpec[] = [
  { provider: Provider.OPEN_AI, llm_type: LLMType.O3_MINI_2025_01_31_HIGH },
  { provider: Provider.ANTHROPIC, llm_type: LLMType.CLAUDE_3_5_SONNET_20241022 },
];

const modelOptions = [
  {
    label: 'Default (Claude + OpenAI)',
    description: 'Best for balanced tasks',
    value: undefined,
  },
  {
    label: 'Claude Sonnet',
    description: 'Best for UI',
    value: { provider: Provider.ANTHROPIC, llm_type: LLMType.CLAUDE_3_5_SONNET_20241022 },
  },
  {
    label: 'OpenAI O3 Mini',
    description: 'Best for complex math',
    value: { provider: Provider.OPEN_AI, llm_type: LLMType.O3_MINI_2025_01_31_HIGH },
  },
];

// eslint-disable-next-line max-lines-per-function
const EditMessageButton = ({
  previousMessageBuilderSessionStateId,
  messageContent,
  messagePrefix,
  currentMessageBuilderSessionStateId,
}: EditMessageButtonProps) => {
  const [selectedLLMModel, setSelectedLLMModel] = useState<BaseLLMSpec | undefined>();
  const [openModelSelectorDropdown, setOpenModelSelectorDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as HTMLElement)) {
      setOpenModelSelectorDropdown(false);
      setSelectedLLMModel(undefined);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleEditUserMessage = (userSelectedLLMSpec?: BaseLLMSpec) => {
    const currentMessageBuilderSessionState = useChatStore
      .getState()
      .appChatSession?.content_builder.find(
        (contentBuilder) =>
          contentBuilder.content.builder_session_state_id === currentMessageBuilderSessionStateId
      );

    const builderInteractionMode = messageContent.startsWith(SOUNDS_GOOD_LETS_BUILD_IT_PROMPT)
      ? BuilderInteractionMode.BUILD
      : currentMessageBuilderSessionState?.content.interaction_mode ??
        useChatStore.getState().currentBuilderInteractionMode;

    useChatStore.setState({
      currentUserPrompt: messageContent,
      messagePrefix,
      isUserEditingMessage: true,
      editedMessageBuilderSessionStateId: previousMessageBuilderSessionStateId,
      currentBuilderInteractionMode: builderInteractionMode,
      userSelectedLLMSpec,
    });
  };

  return (
    <div className={classNames('relative flex gap-1', { 'justify-end': isMobileDevice() })}>
      <button
        data-testid={EDIT_MESSAGE_BUTTON}
        onClick={() => handleEditUserMessage()}
        disabled={useChatStore.getState().userInputLoading}
        className={classNames(
          'text-system-accent md:bg-transparent hover:bg-system-accent/10',
          'rounded-lg p-1 flex gap-1 mb-3 md:mr-0 justify-center items-center',
          { 'cursor-not-allowed': useChatStore.getState().userInputLoading }
        )}
      >
        <EditIcon className="" size={17} />
        <span className="hidden xs:inline-block">{isMobileDevice() ? '' : 'Edit & Retry'}</span>
      </button>
      <button
        disabled={useChatStore.getState().userInputLoading}
        className={classNames(
          'text-system-accent md:bg-transparent hover:bg-system-accent/10',
          'rounded-lg p-1 flex gap-1 mb-3 md:mr-0 justify-center items-center',
          { 'cursor-not-allowed': useChatStore.getState().userInputLoading }
        )}
        onClick={() => setOpenModelSelectorDropdown(!openModelSelectorDropdown)}
      >
        <SparkingIcon className="h-4 w-4 pointer-events-none" />
        <span className="hidden xs:inline-block">
          {isMobileDevice() ? '' : 'Retry with other model'}
        </span>
      </button>
      {openModelSelectorDropdown && (
        <div
          ref={dropdownRef}
          className={classNames(
            'absolute mt-8 bg-white dark:bg-gray-800 border',
            'border-gray-300 dark:border-gray-700 shadow-lg rounded-lg w-64 z-50',
            { 'right-0': isMobileDevice() },
            { 'left-[70px]': !isMobileDevice() }
          )}
        >
          <ul className="py-2">
            {modelOptions.map((option, index) => (
              <li
                key={index}
                className={classNames(
                  'px-4 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700',
                  { 'font-bold': selectedLLMModel === option.value }
                )}
                onClick={() => setSelectedLLMModel(option.value)}
              >
                <div>{option.label}</div>
                <small className="text-gray-500 dark:text-gray-400">{option.description}</small>
              </li>
            ))}
          </ul>
          <ActionButton
            buttonType="primary"
            fillState="filled"
            className="w-full rounded-t-none"
            onClick={() => {
              setOpenModelSelectorDropdown(false);
              handleEditUserMessage(selectedLLMModel);
            }}
            disabled={!selectedLLMModel}
          >
            Retry with selected model
          </ActionButton>
        </div>
      )}
    </div>
  );
};

export default EditMessageButton;
