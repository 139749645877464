import {
  AppInstanceState,
  ContentBuilderMessageMetadata,
  Persona,
  StarterPrompt,
  SuggestedAppSkeleton,
} from './api/generated';

export enum MessageType {
  Text = 'Text',
  AppInput = 'AppInput',
  AppInputPrompt = 'AppInputPrompt',
  AuthenticationRequirement = 'AuthenticationRequirement',
  BuilderSignIn = 'BuilderSignIn',
  LinkCard = 'LinkCard',
  AppStatusChange = 'AppStatusChange',
  DateMessage = 'DateMessage',
}

export enum MessageSourceType {
  User = 'User',
  System = 'System',
  App = 'App',
  Unknown = 'Unknown',
  OS = 'OS',
}

export interface AuthenticationRequirementToMessageContent {
  type: MessageType.AuthenticationRequirement;
  provider: string;
  authType: string;
  oauthSigninLink: string;
  oauthSignedInUser: string;
  redactedApiKey: string;
  fields: string[];
}

export enum TextMessageContentFormat {
  Markdown = 'Markdown',
  Plain = 'Plain',
}

export interface TextMessageContent {
  type: MessageType.Text;
  text: string;
  format: TextMessageContentFormat;
  isErrorTraceback?: boolean;
  isAppUrl?: boolean;
  isSqliteWebUrl?: boolean;
  source?: string;
  prefix?: string;
}

export interface LinkCardMessageContent {
  type: MessageType.LinkCard;
  body: string;
  buttonText: string;
  buttonHref: string;
}

export interface BuilderSignInMessageContent {
  type: MessageType.BuilderSignIn;
}

export interface AppStatusChangeMessageContent {
  type: MessageType.AppStatusChange;
  state: AppInstanceState;
}

export interface MessageSource {
  type: MessageSourceType;
  avatarUrl: string | Map<string, string> | null;
  name: string | Map<string, string> | null | undefined;
}

export type MessageContent =
  | TextMessageContent
  | AuthenticationRequirementToMessageContent
  | BuilderSignInMessageContent
  | LinkCardMessageContent
  | AppStatusChangeMessageContent;

export interface Message {
  id?: string;
  content: MessageContent;
  source: MessageSource;
  builderSessionStateId?: string;
  hasAppVersion?: boolean;
  role?: Persona;
  lastAppVersionPublished?: boolean;
  appVersionPublishDate?: Date;
  sentAt?: Date;
  hasError?: boolean;
  starterPrompts?: StarterPrompt[];
  metadata?: ContentBuilderMessageMetadata;
  suggestedAppSkeletons?: SuggestedAppSkeleton[];
}

export interface MessagePublishInfo {
  lastAppVersionPublished: boolean;
  appVersionPublishDate: Date;
  builderSessionStateId: string;
}

export interface ErrorTracebackFixRequest {
  tryToFixError: boolean;
  missingEnvVarError: boolean;
  errorTraceback: string;
  appId: string;
}

export interface SkeletonInfo {
  title: string;
  appId: string;
}

export interface ActionForParentWindow {
  openPublishModal?: boolean;
  resetOutdatedTestVersionRunningStatus?: boolean;
  submitPrompt?: string;
  submitPromptFromPointAndEdit?: string;
  closeAbilitiesIframe?: boolean;
  selectSkeleton?: SkeletonInfo;
}

export interface ActionToRunAVersionOfTheApp {
  builderSessionStateId: string;
  currentAppRam?: number | null;
  autoUpdateTestApp?: boolean;
  isFromTestingInstance?: boolean;
  appChatUpdateState?: string;
}
