/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Provider {
    OPEN_AI = 'OpenAI',
    AZURE = 'Azure',
    AZURE_DEV = 'AzureDEV',
    GOOGLE = 'Google',
    ANTHROPIC = 'Anthropic',
    DEEPSEEK = 'Deepseek',
}
