import React, { Ref, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { parse } from 'tldts';

import GlobalModalBackdrop from '../../components/GlobalModalBackdrop';
import useModalVisibility from '../../hooks/modalVisibility';
import { LazyApp, LazyAppCustomDomain } from '../../api/generated';
import * as dataTestidConstants from '../../constants';
import classNames from 'classnames';
import { isMobileDevice } from '../../utils/deviceDimensions';
import { useAuthStore } from '../../store/auth';
import {
  getCustomDomainsForApp,
  createCustomDomainForApp,
  markCustomDomainAsVerified,
} from '../../api/BuilderApi';
import ThreeDotsIcon from '../../assets/three-dots.svg';
import { ActionsMenu } from './ActionsMenu';
import { Loader } from '../base/Loader';
import { Input } from '../base/Input';
import ActionButton from '../base/ActionButton';
import Loader4LineIcon from 'remixicon-react/Loader4LineIcon';
import { DomainConfigTable } from './DomainConfigTable';
import ArrowLeftIcon from 'remixicon-react/ArrowLeftLineIcon';
import CloseIcon from 'remixicon-react/CloseLineIcon';
import { UpgradeToProNotice } from '../UpgradeToProNotice';

const SUFFIX: string = '.' + (process.env.REACT_APP_LAZY_PROXY?.replace(/^https?:\/\//, '') || '');

interface CustomDomainsModalProps {
  app: LazyApp;
  onHide: () => void;
}

const DomainVerificationLoader = () => (
  <div className="flex flex-col items-center justify-center gap-3">
    <Loader4LineIcon className="animate-spin text-system-accent" size="3rem" />
    <div
      className="flex flex-col items-center justify-center
     dark:text-dark-label-primary font-semibold"
    >
      <span>Please wait as verifying a custom domain can take up to 1 minute.</span>
      <span>Do not close the browser window.</span>
    </div>
  </div>
);

// eslint-disable-next-line max-lines-per-function, max-statements
const CustomDomainsModal = ({ app, onHide }: CustomDomainsModalProps) => {
  const { itemRef, isVisible } = useModalVisibility(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDomainVerificationInProgress, setIsDomainVerificationInProgress] = useState(false);
  useEffect(() => {
    if (!isVisible && !isDomainVerificationInProgress) {
      onHide();
    }
  }, [isVisible]);

  const { userPermissions } = useAuthStore();
  const [displayAddNewDomainSection, setDisplayAddNewDomainSection] = useState(false);
  const [domainName, setDomainName] = useState<string>('');
  const [lazyHostedDomainName, setLazyHostedDomainName] = useState<string>('');
  const [domainNameError, setDomainNameError] = useState<string>('');
  const [lazyHostedDomainNameError, setLazyHostedDomainNameError] = useState<string>('');
  const [showActionsMenuFor, setShowActionsMenuFor] = useState<null | string>(null);
  const [showVerificationFailedErrorFor, setShowVerificationFailedErrorFor] = useState<
    null | string
  >(null);
  const [configuredCustomDomains, setConfiguredCustomDomains] = useState<LazyAppCustomDomain[]>([]);

  const loadCustomDomains = () => {
    setIsLoading(true);
    return getCustomDomainsForApp(app.id).then((customDomains) => {
      setConfiguredCustomDomains(customDomains);
      setIsLoading(false);
      setDisplayAddNewDomainSection(customDomains.length === 0);
      return null;
    });
  };

  const isDomainNameFilled = domainName.trim().length !== 0;
  const isLazyHostedDomainNameFilled = lazyHostedDomainName.trim().length !== 0;

  useEffect(() => {
    // eslint-disable-next-line no-void
    void loadCustomDomains();
  }, []);

  // eslint-disable-next-line
  const addCustomDomain = (isLazyHosted = false) => {
    setIsLoading(true);

    let standardizedDomainName;
    if (isLazyHosted) {
      standardizedDomainName = lazyHostedDomainName + SUFFIX;
    } else {
      const { subdomain, hostname, domain } = parse(domainName);
      standardizedDomainName = subdomain === 'www' ? domain : hostname;
    }

    if (!standardizedDomainName) {
      setDomainNameError('Please enter valid domain');
      setIsLoading(false);
      return;
    }

    createCustomDomainForApp(app.id, standardizedDomainName as string)
      .then(async () => {
        await loadCustomDomains();
        setIsLoading(false);
        setDomainName('');
        setLazyHostedDomainName('');
        toast.success('Domain added successfully.');
        return '';
      })
      .catch((error) => {
        setIsLoading(false);
        const errorMessage = 'There was an error adding the domain. Please try again.';
        (isLazyHosted ? setLazyHostedDomainNameError : setDomainNameError)(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          (error?.body?.detail?.[0].msg as string) ||
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            (error?.body?.detail as string) ||
            errorMessage
        );
        toast.error(errorMessage);
        return false;
      });
  };

  // eslint-disable-next-line max-lines-per-function
  const verifyDomain = (customDomain: LazyAppCustomDomain) => {
    setIsDomainVerificationInProgress(true);
    // eslint-disable-next-line compat/compat
    markCustomDomainAsVerified(app.id, customDomain.id)
      .then(async () => {
        await loadCustomDomains();
        toast.success('Domain verified successfully.');
        return true;
      })
      .finally(() => {
        setIsDomainVerificationInProgress(false);
      })
      .catch(() => {
        toast.error('Domain verification failed.');
        setShowVerificationFailedErrorFor(customDomain.id);
        return false;
      });
  };

  // eslint-disable-next-line max-lines-per-function
  const renderAddCustomDomainSection = () => (
    <div className="w-full">
      <div className="flex flex-col items-center justify-center gap-2 w-full p-4">
        <div className="flex flex-1 flex-col pr-2 w-full">
          <div className="text-400 mb-1 font-medium">Connect a domain you own</div>
          <div className="text-subtitle-custom-gray text-xs text-400 mb-1">
            Connect a domain purchased through a web hosting service
          </div>
        </div>
        <div className="flex w-full gap-2 items-center">
          <Input
            type="email"
            value={domainName}
            onChange={(e) => {
              setDomainName(e.target.value);
              setDomainNameError('');
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                addCustomDomain();
              }
            }}
            placeholder="yourdomain.com"
          />

          <ActionButton
            className={classNames('dark:bg-dark-secondary', {
              '!bg-system-accent !text-white': isDomainNameFilled,
            })}
            buttonType={isDomainNameFilled ? 'primary' : 'quaternary'}
            onClick={() => addCustomDomain()}
            fillState="light"
            disabled={!isDomainNameFilled}
          >
            Connect
          </ActionButton>
        </div>
        {domainNameError && (
          <span className="w-full text-xs text-red-500 text-left">Error: {domainNameError}</span>
        )}
      </div>
    </div>
  );

  // eslint-disable-next-line max-lines-per-function
  const renderAddLazyCustomDomainSection = () => (
    <div className="w-full">
      <div className="flex flex-col items-center justify-center gap-2 w-full p-4">
        <div className="flex flex-1 flex-col pr-2 w-full">
          <div className="text-400 mb-1 font-medium">Choose a lazy hosted domain name</div>
          <div className="text-subtitle-custom-gray text-xs text-400 mb-1">
            The site name determines the default URL for your site. Only alphanumeric characters and
            hyphens are allowed
          </div>
        </div>
        <div className="flex w-full gap-2 items-center">
          <div
            className="flex flex-row px-3 border border-system-separator
            dark:border-0 items-center justify-center
            dark:bg-dark-background-input rounded-md w-full"
          >
            <Input
              type="email"
              value={lazyHostedDomainName}
              onChange={(e) => {
                setLazyHostedDomainName(e.target.value);
                setLazyHostedDomainNameError('');
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  addCustomDomain(true);
                }
              }}
              placeholder="my-lazy-hosted-site"
              inputClassName="!border-0"
            />
            <span className="text-black dark:text-dark-label-primary">{SUFFIX}</span>
          </div>

          <ActionButton
            className={classNames({
              '!bg-system-accent !text-white': isLazyHostedDomainNameFilled,
            })}
            buttonType={isLazyHostedDomainNameFilled ? 'primary' : 'quaternary'}
            onClick={() => addCustomDomain(true)}
            fillState="light"
            disabled={!isLazyHostedDomainNameFilled}
          >
            Add
          </ActionButton>
        </div>
        {lazyHostedDomainNameError && (
          <span className="w-full text-xs text-red-500 text-left">
            Error: {lazyHostedDomainNameError}
          </span>
        )}
      </div>
    </div>
  );

  // eslint-disable-next-line max-lines-per-function
  const renderExistingDomains = () => (
    <div className="h-full flex justify-between flex-col">
      {
        // eslint-disable-next-line max-lines-per-function
        configuredCustomDomains.map((customDomain, index) => (
          <div key={index} className="flex p-4 w-full items-center justify-center gap-2 ">
            <span
              className={classNames('font-medium text-label-secondary self-start', {
                'pt-1.5': !customDomain.is_verified,
              })}
            >
              URL:
            </span>
            <div className="flex flex-col flex-1">
              <div className="flex items-center justify-center">
                <div className="flex flex-1 gap-1 justify-start items-center pl-10">
                  <div
                    className={classNames('h-2 w-2 rounded-xl', {
                      'bg-system-success': customDomain.is_verified,
                      'bg-orange-400': !customDomain.is_verified,
                      'bg-red-500': showVerificationFailedErrorFor === customDomain.id,
                    })}
                  />
                  <a
                    href={`http${customDomain.is_verified ? 's' : ''}://${customDomain.name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`http${customDomain.is_verified ? 's' : ''}://${customDomain.name}`}
                  </a>
                </div>
                {!customDomain.name.endsWith(SUFFIX) && (
                  <div>
                    {!customDomain.is_verified && (
                      <ActionButton
                        buttonType="primary"
                        onClick={() => verifyDomain(customDomain)}
                        fillState="subtle"
                      >
                        Verify Domain
                      </ActionButton>
                    )}
                  </div>
                )}
                <div
                  className="flex w-5 h-5 cursor-pointer relative"
                  onClick={() => setShowActionsMenuFor(customDomain.id)}
                >
                  <img src={ThreeDotsIcon as string} alt="Three Dots Icon" />
                  {showActionsMenuFor === customDomain.id && (
                    <ActionsMenu
                      app={app}
                      customDomain={customDomain}
                      onClose={() => setShowActionsMenuFor(null)}
                      setIsLoading={(loading: boolean) => {
                        if (!loading) {
                          // eslint-disable-next-line no-void
                          void loadCustomDomains();
                        }
                        setIsLoading(loading);
                      }}
                    />
                  )}
                </div>
              </div>
              {!customDomain.is_verified && !customDomain.name.endsWith(SUFFIX) && (
                <DomainConfigTable
                  domainName={customDomain.name}
                  showVerificationFailedErrorMessage={
                    showVerificationFailedErrorFor === customDomain.id
                  }
                />
              )}
            </div>
          </div>
        ))
      }
      <span className="p-4 text-xs text-label-grey font-semibold">
        Note: You would need to restart the app after configuring the custom domain.
      </span>
    </div>
  );

  return (
    <div
      className="flex items-center justify-center h-full w-full absolute z-[55]"
      data-testid={dataTestidConstants.PUBLISH_PRODUCTION_APP_MODAL}
    >
      <GlobalModalBackdrop />
      {isLoading && !isDomainVerificationInProgress && <Loader />}
      {isDomainVerificationInProgress && <Loader loaderContent={DomainVerificationLoader} />}
      <div
        ref={itemRef as Ref<HTMLDivElement>}
        className="rounded-md bg-white shadow-2xl z-[100]
         h-full w-screen md:max-w-xl md:h-auto dark:text-dark-label-primary dark:bg-dark-primary"
      >
        <div
          className={classNames('flex flex-col overflow-scroll', {
            'h-full': isMobileDevice(),
            'h-[440px]': !isMobileDevice(),
          })}
        >
          <div
            className={classNames(
              'hover:dark:!bg-dark-teritary',
              'flex flex-row justify-end py-2',
              {
                'justify-between': configuredCustomDomains.length > 0 && displayAddNewDomainSection,
              }
            )}
          >
            {configuredCustomDomains.length > 0 && displayAddNewDomainSection && (
              <ActionButton
                onClick={() => setDisplayAddNewDomainSection(false)}
                buttonType="tertiary"
                fillState="subtle"
                className="hover:!bg-white"
              >
                <ArrowLeftIcon className="h-4" /> Back
              </ActionButton>
            )}
            <div onClick={onHide} className="px-2 py-1 dark:text-dark-label-primary">
              <CloseIcon className="h-6" />
            </div>
          </div>
          <hr className="dark:border-dark-system-separator border-system-separator" />
          <div className="flex flex-row justify-between p-4 pb-0">
            <div className="font-semibold text-base">Custom domain</div>
          </div>
          {!userPermissions?.isUserAllowedToConfigureCustomDomain ? (
            <UpgradeToProNotice />
          ) : displayAddNewDomainSection ? (
            <div>
              {renderAddCustomDomainSection()}
              <span className="p-4 text-md font-bold">Or</span>
              {renderAddLazyCustomDomainSection()}
            </div>
          ) : (
            renderExistingDomains()
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomDomainsModal;
