import React, { useEffect, useState } from 'react';
import { AppInstanceState } from '../api/generated';
import { RunAppButton } from './base/RunAppButton';
import StopAppIcon from 'remixicon-react/StopFillIcon';
import DatabaseIcon from 'remixicon-react/Database2LineIcon';
import GlobalIcon from 'remixicon-react/GlobalLineIcon';
import * as dataTestidConstants from '../constants';
import { useChatStore } from '../store/chat';
import { FrontendEvents } from '../api/StatsApi';
import { isMobileDevice } from '../utils/deviceDimensions';
import { getAuthCookie } from '../api/TtyHelper';
import { toast } from 'react-toastify';
import { TOP_BAR_VIEW_DATABASE_BUTTON } from '../constants';
import ActionButton from './base/ActionButton';

interface AppInstanceStatusDisplayProps {
  state: AppInstanceState;
  onStopApp: () => void;
  onStartApp: (ramMb: number, sutoUpdateTestApp: boolean) => void;
  isNewVersionAvailable?: boolean;
  isRestarting?: boolean;
  isStopping?: boolean;
  isRunning?: boolean;
  openAppUrl: string | null;
  sqliteWebUrl: string | null;
  emitEvent: (eventType: FrontendEvents) => void;
  currentRam?: number;
  isTestingApp?: boolean;
  handleSaveInstanceSettings: () => void;
}

const AppInstanceStatusDisplay = React.forwardRef<HTMLDivElement, AppInstanceStatusDisplayProps>(
  // eslint-disable-next-line max-lines-per-function
  (
    {
      state,
      onStopApp,
      onStartApp,
      isNewVersionAvailable,
      isRestarting,
      isRunning,
      isStopping,
      openAppUrl,
      sqliteWebUrl,
      emitEvent,
      currentRam,
      isTestingApp,
      handleSaveInstanceSettings,
    },
    ref
  ) => {
    const shouldAllowStartingApp =
      [
        AppInstanceState.ENDED,
        AppInstanceState.STOPPED,
        AppInstanceState.ENDED_WITH_ERROR,
        AppInstanceState.UNKNOWN_ERROR,
        AppInstanceState.NEVER_RUN,
      ].indexOf(state) >= 0;

    const shouldAllowStoppingApp = !shouldAllowStartingApp;

    const stateFirstRun = state === AppInstanceState.NEVER_RUN;
    const error = useChatStore((state) => state.error);

    const [openAppUrlToRender, setOpenAppUrlToRender] = useState<string | null>(null);
    const [sqliteWebUrlToRender, setSqliteWebUrlToRender] = useState<string | null>(null);

    useEffect(() => {
      if (openAppUrl) {
        if (!openAppUrlToRender) {
          setTimeout(() => setOpenAppUrlToRender(openAppUrl), 2000);
        }
      } else {
        setOpenAppUrlToRender(null);
      }
    }, [openAppUrl]);

    useEffect(() => {
      if (sqliteWebUrl) {
        if (!sqliteWebUrlToRender) {
          setTimeout(() => setSqliteWebUrlToRender(sqliteWebUrl), 2000);
        }
      } else {
        setSqliteWebUrlToRender(null);
      }
    }, [sqliteWebUrl]);

    if (error) {
      return null;
    }

    return (
      <div ref={ref} className="flex gap-2">
        {isRunning && sqliteWebUrlToRender && (
          <ActionButton
            buttonType="primary"
            fillState="subtle"
            className="border border-system-blue-6"
            onClick={() => {
              emitEvent(FrontendEvents.USER_CLICKED_SHOW_DB_BUTTON);

              // Open a blank tab immediately (Safari will allow this)
              const newTab = window.open('', '_blank');

              getAuthCookie()
                .then(() => {
                  // If authentication succeeds, update the tab's location
                  if (newTab) {
                    newTab.location.href = sqliteWebUrlToRender;
                  }
                  return null;
                })
                .catch(() => {
                  toast.error('Something went wrong. Please refresh the page and try again.');
                  if (newTab) {
                    newTab.close();
                  } // Close tab on error
                });
            }}
            dataTestid={dataTestidConstants.TEST_APP_VIEW_DATABASES_APP_BUTTON as string}
          >
            <DatabaseIcon size={20} />
            {!isMobileDevice() && (
              <span data-testid={TOP_BAR_VIEW_DATABASE_BUTTON}>View database</span>
            )}
          </ActionButton>
        )}
        {isRunning && openAppUrlToRender && (
          <ActionButton
            buttonType="primary"
            fillState="subtle"
            className="border border-system-blue-6"
            onClick={() => {
              emitEvent(FrontendEvents.USER_CLICKED_OPEN_APP_BUTTON);
              window.open(openAppUrlToRender, '_blank');
            }}
            dataTestid={dataTestidConstants.TEST_APP_OPEN_APP_BUTTON as string}
          >
            <GlobalIcon size={20} />
            {!isMobileDevice() && <span> Open App</span>}
          </ActionButton>
        )}
        {shouldAllowStartingApp && (
          <RunAppButton
            isAppRestarting={!!isRestarting}
            onRunApp={onStartApp}
            isFirstRun={stateFirstRun}
            isNewVersionAvailable={isNewVersionAvailable}
            currentRam={currentRam}
            isTestingApp={isTestingApp}
            handleSaveInstanceSettings={handleSaveInstanceSettings}
          />
        )}
        {shouldAllowStoppingApp && (
          <ActionButton
            buttonType="danger"
            fillState="subtle"
            className="border border-system-danger"
            onClick={onStopApp}
            disabled={isStopping}
            isLoading={isStopping}
            dataTestid={dataTestidConstants.TEST_APP_STOP_APP_BUTTON}
          >
            <StopAppIcon size={20} />
            {!isMobileDevice() && <span> Stop App</span>}
          </ActionButton>
        )}
      </div>
    );
  }
);

export default AppInstanceStatusDisplay;
