import React, { Ref, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ReactComponent as CrossIcon } from '../assets/cross-icon.svg';
import GlobalModalBackdrop from '../components/GlobalModalBackdrop';
import { ReactComponent as StatusCircleIcon } from '../assets/status-circle-icon.svg';
import useModalVisibility from '../hooks/modalVisibility';
import { relativeTimeFromDates } from '../utils/time';
import ActionButton from './base/ActionButton';
import { LazyApp } from '../api/generated';
import { FrontendEvents } from '../api/StatsApi';
import { useEventEmitter } from './eventEmitterHook';
import * as dataTestidConstants from '../constants';

interface PublishTemplateModalProps {
  app: LazyApp;
  onHide: () => void;
  onPublishTemplate: (name: string, description: string, videoUrl: string) => Promise<void>;
  publishedUrl: string | null;
  lastPublishedAt: Date | null;
}

// eslint-disable-next-line max-lines-per-function, max-statements
const PublishTemplateModal = ({
  app,
  onHide,
  onPublishTemplate,
  publishedUrl,
  lastPublishedAt,
}: PublishTemplateModalProps) => {
  const { itemRef, isVisible } = useModalVisibility(true);
  useEffect(() => {
    if (!isVisible) {
      onHide();
    }
  }, [isVisible]);
  const { emitEvent } = useEventEmitter();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(app.name || '');
  const [description, setDescription] = useState(app.description || '');
  const [videoUrl, setVideoUrl] = useState(app.video_url || '');
  const [errorMessage, setErrorMessage] = useState('');

  const validateUrl = (url: string) => {
    // Simple URL validation, you can use a more advanced validation method if needed
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return pattern.test(url);
  };

  const handleVideoUrlChange = (url: string) => {
    setVideoUrl(url);
    // Validate URL
    const isValidUrl = validateUrl(url);
    if (url.trim().length !== 0 && !isValidUrl) {
      setErrorMessage('Please enter a valid URL.');
    } else {
      setErrorMessage('');
    }
  };

  const emitPublishEvent = () => {
    emitEvent(
      lastPublishedAt
        ? FrontendEvents.USER_PUBLISHED_TEMPLATE_UPDATE
        : FrontendEvents.USER_PUBLISHED_TEMPLATE,
      app.id
    );
  };

  const handlePublish = () => {
    setLoading(true);

    onPublishTemplate(name, description, videoUrl)
      .then(() => {
        emitPublishEvent();
        setLoading(false);
        toast.success('Template published successfully.');
        return '';
      })
      .catch(() => {
        setLoading(false);
        toast.error('There was an error publishing the app. Please try again later.');
        return false;
      });
  };

  const renderTemplatePublishingForm = () => (
    <>
      <div className="flex flex-col items-start px-6 w-full">
        <div className="text-xs">Name</div>
        <input
          className="border separator-colors-opaque rounded-sm w-full px-2 py-1"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="flex flex-col items-start px-6 w-full">
        <div className="text-xs">Description</div>
        <textarea
          className={
            'border separator-colors-opaque rounded-sm' + ' w-full px-2 py-1 h-24 resize-none'
          }
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className="flex flex-col items-start px-6 w-full">
        <div className="text-xs">Video Link</div>
        <input
          className="border separator-colors-opaque rounded-sm w-full px-2 py-1"
          type="url"
          value={videoUrl}
          onChange={(e) => handleVideoUrlChange(e.target.value)}
        />
        {errorMessage && <div className="text-red-500 text-xs">{errorMessage}</div>}
      </div>
    </>
  );

  const renderPublishButton = () => (
    <ActionButton
      className="bg-purple-500 text-white hover:bg-purple-500/80"
      onClick={handlePublish}
      isLoading={loading}
      disabled={errorMessage.length !== 0}
    >
      Publish now
    </ActionButton>
  );

  const renderCloseModalButton = () => (
    <ActionButton
      buttonType="tertiary"
      onClick={onHide}
      fillState="subtle"
      dataTestid={dataTestidConstants.CLOSE_PUBLISH_PRODUCTION_APP_MODAL}
    >
      Close
    </ActionButton>
  );

  const renderBottomActionButtons = () => (
    <>
      <hr className="dark:border-dark-system-separator border-system-separator" />
      <div className="flex p-4 justify-end gap-2 items-end self-end h-full md:h-auto">
        {renderCloseModalButton()}
        {renderPublishButton()}
      </div>
    </>
  );

  return (
    <div
      className="flex items-center justify-center h-full w-full absolute z-[55]"
      data-testid={dataTestidConstants.PUBLISH_PRODUCTION_APP_MODAL}
    >
      <GlobalModalBackdrop />
      <div
        ref={itemRef as Ref<HTMLDivElement>}
        className="rounded-md bg-white shadow-2xl z-[100] h-full w-screen md:max-w-xl md:h-auto"
      >
        <div className="flex flex-col overflow-scroll">
          <div className="flex flex-row justify-between p-4">
            <div className="font-semibold text-base">Publish Community Version</div>
            <button onClick={onHide}>
              <CrossIcon className="w-5" />
            </button>
          </div>
          <hr className="dark:border-dark-system-separator border-system-separator" />
          <div className="flex h-full flex-col gap-4 p-4 items-center justify-start">
            <div className="flex flex-row items-start justify-center gap-2 w-full">
              <div className="flex items-start flex-col gap-2 w-full">
                {publishedUrl ? (
                  <div className="flex w-full">
                    <div className="flex flex-1 flex-col px-6">
                      <div className="text-subtitle-custom-gray text-xs text-400 mb-1">
                        Community version updated {relativeTimeFromDates(lastPublishedAt)}
                      </div>
                      <div className="flex flex-row items-center">
                        <StatusCircleIcon />
                        <a
                          className="font-medium text-xs px-1 text-system-blue-6 truncate max-w-xs"
                          href={publishedUrl}
                          target="_blank"
                          data-testid={dataTestidConstants.APP_PRODUCTION_VERSION_LINK}
                        >
                          {publishedUrl}
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center gap-6">
                    <div className="text-subtitle-custom-gray text-400 px-6">
                      No version published yet
                    </div>
                  </div>
                )}
                {renderTemplatePublishingForm()}
              </div>
            </div>
          </div>
          {renderBottomActionButtons()}
        </div>
      </div>
    </div>
  );
};

export default PublishTemplateModal;
