import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import React, { useCallback, useEffect, useState } from 'react';
import { File, FileFormat, FileInfo, InstanceService } from '../../api/generated';
import debounce from 'lodash.debounce';
import WebSocketManager from './vsCodeWebSocketManager';
import {
  AppFilesInfo,
  Message,
  MessageSource,
  MessageType,
  VSCodeConnectionStatus,
} from './models';
import Button from '../base/Button';
import classNames from 'classnames';
import { isMobileDevice } from '../../utils/deviceDimensions';
import { useChatStore } from '../../store/chat';
import { ReconnectModal } from '../ReconnectModal';
import { useTabStore } from '../../store/tab';
import SignalWifiError from 'remixicon-react/SignalWifiErrorLineIcon';
import CloudConnected from 'remixicon-react/CloudFillIcon';
import CloudDisConnected from 'remixicon-react/CloudOffFillIcon';
import RefreshConnection from 'remixicon-react/RefreshLineIcon';
import CloseIcon from 'remixicon-react/CloseLineIcon';
import SaveFile from 'remixicon-react/Save2LineIcon';
import { ApproveModal } from '../base/ApproveModal';
import {
  CANCEL_CLEAR_CODE_CHANGES,
  CLEAR_CODE_CHANGES_BUTTON_TEST_ID,
  CONFIRM_CLEAR_CODE_CHANGES,
  SAVE__CODE_BUTTON_TEST_ID,
} from '../../constants';
import { sendFilesToBeUploaded } from './utils';
import { useAppStore } from '../../store/app';
export const EMPTY_FILE: File = {
  name: '',
  path: '',
  content: '',
  format: FileFormat.PYTHON,
};

interface VSCodeEditorPOCProps {
  files?: File[];
  modifiedFiles?: File[];
  appName?: string;
  vsCodeWebSocket?: WebSocketManager;
  appId: string;
  updateFile: (updatedFiles: File[]) => Promise<void>;
  grabFocus: (holdingFocus: boolean) => void;
}

// eslint-disable-next-line max-lines-per-function, max-statements
const VSCodeEditorPOC: React.FC<VSCodeEditorPOCProps> = ({
  files,
  modifiedFiles,
  appName,
  updateFile,
  vsCodeWebSocket,
  appId,
}) => {
  const [isFilesModified, setIsFilesModified] = useState<boolean>(false);
  const [isVSCodeSavingFiles, setIsVSCodeSavingFiles] = useState<boolean>(false);
  const [isFilesBeingHosted, setIsFilesBeingHosted] = useState<boolean>(false);
  const [showReconnectModal, setShowReconnectModal] = useState(false);
  const [showClearCodeChangesConfirmationModal, setShowClearCodeChangesConfirmationModal] =
    useState(false);
  const vscodeWebsocketConnectionStatus = useTabStore((state) => state.vscodeConnectionStatus);

  const SAVE_BUTTON_TIMEOUT = 10 * 1000; // set to 10 seconds.

  // callback to handle retrieved messages for saving new files.
  vsCodeWebSocket?.setMessageReceivedCallback((filesToSave: File[]) => {
    setIsVSCodeSavingFiles(false);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateFile(filesToSave);
  });

  const sendUpdate = useCallback(
    debounce((data: Message) => {
      vsCodeWebSocket?.send(data);
    }, 300),
    []
  );

  const triggerSaveAction = () => {
    if (vscodeWebsocketConnectionStatus !== VSCodeConnectionStatus.OPEN) {
      if (vscodeWebsocketConnectionStatus !== VSCodeConnectionStatus.OPEN) {
        setShowReconnectModal(true);
      }
    } else {
      setIsVSCodeSavingFiles(true);
      sendUpdate({ type: MessageType.COMMAND, source: MessageSource.LAZY, content: 'save_files' });
      setTimeout(() => {
        setIsVSCodeSavingFiles(false);
      }, SAVE_BUTTON_TIMEOUT);
    }
  };

  const clearCodeChanges = () => {
    if (vscodeWebsocketConnectionStatus !== VSCodeConnectionStatus.OPEN) {
      if (vscodeWebsocketConnectionStatus !== VSCodeConnectionStatus.OPEN) {
        setShowReconnectModal(true);
      }
    } else {
      sendUpdate({
        type: MessageType.COMMAND,
        source: MessageSource.LAZY,
        content: 'clear_changes',
      });
      setShowClearCodeChangesConfirmationModal(false);
    }
  };

  async function fetchRemoteFile(accessLink: string): Promise<ArrayBuffer> {
    const response = await fetch(accessLink);
    return response.arrayBuffer();
  }

  async function getFileContentFromContentHash(
    contentHash: string
  ): Promise<ArrayBuffer | undefined> {
    const encodedHash = encodeURIComponent(contentHash);
    const response = await InstanceService.instanceGetUploadedResourceAuthenticationToken(
      encodedHash
    );

    if (response && process.env.REACT_APP_LAZY_PROXY) {
      const accessLink = `${process.env.REACT_APP_LAZY_PROXY}/resource?resource=${response}`;
      return fetchRemoteFile(accessLink);
    }
    return undefined;
  }

  async function downloadFilesAsZip(files: File[], archiveName?: string): Promise<void> {
    const zip = new JSZip();

    await Promise.all(
      files.map(async (file) => {
        if (!file.deleted && file.path && file.name && (file.content || file.content_hash)) {
          const filePath = `${file.path.replace('./', '')}${file.name}`;
          let fileContent: string | ArrayBuffer = file.content || '';

          if (file.content_hash) {
            fileContent =
              (await getFileContentFromContentHash(file.content_hash)) || new ArrayBuffer(0);
          }

          zip.file(filePath, fileContent);
        }
      })
    );

    const blob = await zip.generateAsync({ type: 'blob' });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    saveAs(blob, `${archiveName || appName || 'download'}.zip`);
  }

  vsCodeWebSocket?.setSignalReceivedCallback((signal: string) => {
    if (signal === 'files_populated') {
      useTabStore.getState().setVSCodeLoaded(true);
    } else if (signal === 'lazy_vscode_fs_provider_ready') {
      const message: Message = {
        source: MessageSource.LAZY,
        type: MessageType.APP_INFO_FILES,
        content: {
          oldFiles: files,
          newFiles: modifiedFiles?.length ? modifiedFiles : files || [],
          appName: appName || 'Untitled',
          builderSessionStateId: useChatStore.getState().currentBuilderSessionStateId,
          canSaveFiles: !useChatStore.getState().isViewingVersion,
        } as AppFilesInfo,
      };
      sendUpdate(message);
    } else if (signal === 'files_changed') {
      setIsFilesModified(true);
    } else if (signal === 'files_unchanged') {
      setIsFilesModified(false);
    }
  });

  // eslint-disable-next-line max-lines-per-function
  vsCodeWebSocket?.setVSCodeCommandsCallback((message: Message) => {
    if (message.command === 'download') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const archiveName = message.commandParams
        ? message.commandParams.archiveName
          ? message.commandParams.archiveName
          : appName
        : appName;
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      downloadFilesAsZip(message.content as File[], archiveName);
    } else if (message.command === 'host_files') {
      if (process.env.REACT_APP_LAZY_IS_VSCODE_FILE_UPLOAD_HOSTING_ENABLED === 'false') {
        return vsCodeWebSocket.send({
          source: MessageSource.LAZY,
          type: MessageType.COMMAND,
          command: 'save_without_upload',
          content: '',
        });
      } else {
        const filesToBeHosted = message.content as FileInfo[];
        if (filesToBeHosted.length > 0 && appId) {
          setIsFilesBeingHosted(true);
          const filesToBeHostedPromises = sendFilesToBeUploaded(filesToBeHosted);
          // eslint-disable-next-line max-len
          // eslint-disable-next-line @typescript-eslint/no-floating-promises, promise/catch-or-return
          Promise.all(filesToBeHostedPromises).then((response) => {
            setIsFilesBeingHosted(false);
            return vsCodeWebSocket.send({
              source: MessageSource.LAZY,
              type: MessageType.COMMAND,
              command: 'update_files_access_link_and_save',
              content: response,
            });
          });
        }
      }
    } else if (message.command === 'request_access_url') {
      const fullFileAccessPath = encodeURIComponent(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        (message.content as FileInfo[])[0].content_hash as string
      );
      // eslint-disable-next-line @typescript-eslint/no-floating-promises, promise/catch-or-return
      InstanceService.instanceGetUploadedResourceAuthenticationToken(fullFileAccessPath).then(
        (response) => {
          // eslint-disable-next-line promise/always-return
          if (response && process.env.REACT_APP_LAZY_PROXY) {
            vsCodeWebSocket.send({
              content: [
                {
                  name: (message.content as FileInfo[])[0].name,
                  path: (message.content as FileInfo[])[0].path,
                  access_url: `${process.env.REACT_APP_LAZY_PROXY}/resource?resource=${response}`,
                },
              ],
              source: MessageSource.LAZY,
              type: MessageType.COMMAND,
              command: 'show_files',
            });
          }
        }
      );
    }
  });

  useEffect(() => {
    setIsFilesModified(false);
    useTabStore.getState().setVSCodeLoaded(false);
    const message: Message = {
      source: MessageSource.LAZY,
      type: MessageType.APP_INFO_FILES,
      content: {
        oldFiles: files,
        newFiles: modifiedFiles?.length ? modifiedFiles : files || [],
        appName: appName || 'Untitled',
        builderSessionStateId: useChatStore.getState().currentBuilderSessionStateId,
        canSaveFiles: !useChatStore.getState().isViewingVersion,
      } as AppFilesInfo,
    };
    sendUpdate(message);
  }, [modifiedFiles, files, appName, sendUpdate]);

  useEffect(() => {
    vsCodeWebSocket?.setReconnectionFailedCallback(() => {
      setShowReconnectModal(true);
      useTabStore.getState().setVSCodeLoaded(true);
    });

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      vsCodeWebSocket?.setReconnectionFailedCallback(() => {}); // Clean up the callback
    };
  }, []);

  useEffect(() => {
    const message: Message = {
      content: '',
      source: MessageSource.LAZY,
      command: 'change_vscode_theme',
      commandParams: { isDarkThemeEnabled: useAppStore.getState().isDark },
      type: MessageType.COMMAND,
    };
    vsCodeWebSocket?.send(message);
  }, [useAppStore.getState().isDark]);

  return (
    <div className="w-full h-full flex-col">
      {showReconnectModal && (
        <ReconnectModal
          onReconnect={() => {
            setShowReconnectModal(false);
            // Trigger reconnection attempt again
            vsCodeWebSocket?.reconnect();
          }}
          onCancel={() => {
            setShowReconnectModal(false);
          }}
        />
      )}
      {showClearCodeChangesConfirmationModal && (
        <ApproveModal
          message={'Are you sure you want to clear code changes?'}
          onApprove={() => {
            clearCodeChanges();
          }}
          onReject={() => {
            setShowClearCodeChangesConfirmationModal(false);
          }}
          approveButton={{
            text: 'Clear',
            className: 'bg-system-accent text-white',
            dataTestId: CONFIRM_CLEAR_CODE_CHANGES,
          }}
          rejectButton={{
            text: 'Cancel',
            className: 'bg-white text-label-secondary',
            dataTestId: CANCEL_CLEAR_CODE_CHANGES,
          }}
        />
      )}
      <div
        className={classNames('flex absolute right-500 justify-center items-center gap-2', {
          'top-[55px]': !isMobileDevice(),
          'top-[110px]': isMobileDevice(),
          'top-[95px]': !isMobileDevice() && useChatStore.getState().isViewingVersion,
          'top-[160px]': isMobileDevice() && useChatStore.getState().isViewingVersion,
        })}
      >
        {vscodeWebsocketConnectionStatus === VSCodeConnectionStatus.TIMEOUT ? (
          isMobileDevice() ? (
            <span className="text-system-danger">Discon</span>
          ) : (
            <span className="text-system-danger">Disconnected</span>
          )
        ) : vscodeWebsocketConnectionStatus === VSCodeConnectionStatus.CLOSED ? (
          isMobileDevice() ? (
            <span>Recon</span>
          ) : (
            <span>Reconnecting</span>
          )
        ) : isMobileDevice() ? (
          <span className="text-system-success">Conn</span>
        ) : (
          <span className="text-system-success">Connected</span>
        )}

        {vscodeWebsocketConnectionStatus === VSCodeConnectionStatus.TIMEOUT && (
          <>
            <CloudDisConnected size={16} color="#DC2626"></CloudDisConnected>
            <RefreshConnection
              size={16}
              color="#007AFF"
              onClick={() => {
                vsCodeWebSocket?.reconnect();
                setShowReconnectModal(false);
              }}
              className="cursor-pointer"
            ></RefreshConnection>
          </>
        )}
        {vscodeWebsocketConnectionStatus === VSCodeConnectionStatus.OPEN && (
          <CloudConnected size={16} color="#208A3C"></CloudConnected>
        )}
        {vscodeWebsocketConnectionStatus === VSCodeConnectionStatus.CLOSED && (
          <SignalWifiError size={16}></SignalWifiError>
        )}
      </div>

      {isFilesModified && (
        <div className="flex justify-center">
          <Button
            className={classNames(
              'absolute bg-lazy-label-red bottom-0 text-white h-[24px] absolute w-[30px]',
              {
                'top-[54px] w-[100px] right-[170px]': !isMobileDevice(),
                'top-[106px] right-[50px] ': isMobileDevice(),
              }
            )}
            onClick={() => setShowClearCodeChangesConfirmationModal(true)}
            iconProps={{ icon: CloseIcon, iconSize: 16 }}
            dataTestid={CLEAR_CODE_CHANGES_BUTTON_TEST_ID}
          ></Button>
          <Button
            loading={
              isVSCodeSavingFiles || isFilesBeingHosted || useChatStore.getState().userInputLoading
            }
            className={classNames('bg-system-green-7 text-white absolute h-[24px] right-[15px]', {
              'w-[150px] top-[54px]': !isMobileDevice(),
              'w-[30px] top-[106px]': isMobileDevice(),
            })}
            onClick={triggerSaveAction}
            iconProps={{ icon: SaveFile, iconSize: 16 }}
            dataTestid={SAVE__CODE_BUTTON_TEST_ID}
          >
            {isMobileDevice() ? '' : 'Save code'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default VSCodeEditorPOC;
