import React, { Ref, useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames';
import { isMobileDevice } from '../../utils/deviceDimensions';
import { useChatStore } from '../../store/chat';
import GlobalModalBackdrop from '../../components/GlobalModalBackdrop';
import { Loader } from '../base/Loader';

// eslint-disable-next-line max-lines-per-function
export const AbilitiesAppIframe = forwardRef((_, ref) => {
  const iframeRef: Ref<HTMLIFrameElement> = useRef<HTMLIFrameElement>(null);
  const [isLoading, setIsLoading] = useState(true);

  // This allows the parent component to access the iframeRef
  useImperativeHandle(ref, () => ({
    getIframe: () => iframeRef.current,
  }));

  const handleClickOutside = (event: MouseEvent) => {
    if (iframeRef.current && !iframeRef.current.contains(event.target as HTMLElement)) {
      useChatStore.setState({ showAbilitiesIframe: false });
    }
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="flex items-center justify-center h-full w-full absolute z-[55]">
      <GlobalModalBackdrop />
      {isLoading && <Loader fullScreen={false} />}
      <div
        className={classNames('fixed  z-[100]', {
          'w-[70%] h-[70%]': !isMobileDevice(),
          'w-full h-full': isMobileDevice(),
        })}
      >
        <iframe
          ref={iframeRef}
          name="lazy-abilities-app"
          className={classNames('flex h-full w-full')}
          src="https://app--dca5f737-2a7c-483e-8a12-5e52c082d3d1.app.getlazy.ai?dark_mode=True"
          data-hj-allow-iframe
          onLoad={() => {
            document.addEventListener('click', handleClickOutside);
            setIsLoading(false);
          }}
        ></iframe>
      </div>
    </div>
  );
});
