import classNames from 'classnames';
import React from 'react';
import FilterLineIcon from 'remixicon-react/FilterLineIcon';
import SearchLineIcon from 'remixicon-react/SearchLineIcon';

import Button from '../../base/Button';

interface AppsSearchInputFilterProps {
  searchFilter: string;
  onSearchFilterChange: (value: string) => void;
  showAdvanceFilters: boolean;
  onShowFiltersChange: (value: boolean) => void;
}

// eslint-disable-next-line max-lines-per-function
const AppsSearchInputFilter: React.FC<AppsSearchInputFilterProps> = ({
  searchFilter,
  onSearchFilterChange,
  showAdvanceFilters,
  onShowFiltersChange,
}) => {
  return (
    <div className="px-3 flex w-full gap-2 justify-between">
      <div
        className={
          'flex flex-1 rounded-full items-center pl-2 pr-2 ' +
          'gap-1.5 bg-white border border-system-separator' +
          'dark:text-dark-label-primary dark:bg-dark-background-input'
        }
      >
        <SearchLineIcon size={16} className="text-label-tertiary" />
        <input
          type="text"
          placeholder="Search"
          className="outline-none w-full dark:bg-dark-background-input dark:text-dark-label-primary"
          value={searchFilter}
          onChange={(e) => onSearchFilterChange(e.target.value)}
        ></input>
      </div>
      <Button
        onClick={() => onShowFiltersChange(!showAdvanceFilters)}
        className="bg-colors-neutral-100 dark:bg-dark-tertiary"
      >
        <FilterLineIcon
          size={20}
          className={classNames({
            'text-system-accent': showAdvanceFilters,
            'text-label-primary dark:text-dark-label-primary': !showAdvanceFilters,
          })}
        />
      </Button>
    </div>
  );
};

export default AppsSearchInputFilter;
