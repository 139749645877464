import { TabBarItemsIndex } from '../../../constants';
import React from 'react';
import ArrowRightIcon from 'remixicon-react/ArrowRightLineIcon';
import ActionButton from '../../base/ActionButton';

interface MissingSecretsActionProps {
  selectedTabIndex: React.Dispatch<React.SetStateAction<TabBarItemsIndex>>;
}
const MissingSecretsAction = (props: MissingSecretsActionProps) => {
  const navigateToEnvSecretsTab = () => {
    props.selectedTabIndex(TabBarItemsIndex.APP_ENV_SECRETS);
  };
  return (
    <ActionButton
      className="w-full !justify-between py-2 text-sm font-medium leading-5 !h-10
    bg-violet-50 cursor-pointer text-violet-500 "
      buttonType="primary"
      fillState="light"
      onClick={navigateToEnvSecretsTab}
    >
      <span>Add Environment Secrets</span>
      <span>
        <ArrowRightIcon size={20} className="flex-1" />
      </span>
    </ActionButton>
  );
};

export default MissingSecretsAction;
