import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import slothOnHammock from '../../lotties/sloth-on-hammock.json';
import slothOnRocket from '../../lotties/sloth-on-rocket.json';
import { SHUFFLED_LOADING_MESSAGES } from '../../i18n';
import Typewriter from 'typewriter-effect';

// eslint-disable-next-line max-lines-per-function
export const LoaderWithSlothLotties = () => {
  const [useRocket, setUseRocket] = useState(false);

  useEffect(() => {
    setUseRocket(true);
  }, []);

  return (
    <div
      className="flex flex-1 flex-col items-center backdrop-blur-md text-lazy
     dark:text-dark-label-primary justify-center"
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: useRocket ? slothOnRocket : slothOnHammock,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={120}
        width={120}
      />
      <div className="text-xl">
        <Typewriter
          options={{
            strings: SHUFFLED_LOADING_MESSAGES,
            autoStart: true,
            delay: 75,
            deleteSpeed: 15,
            loop: true,
          }}
        />
      </div>
    </div>
  );
};
