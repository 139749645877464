import React from 'react';
import { ReactComponent as LazyLogo } from '../assets/lazy-logo.svg';
import { ReactComponent as WarningIcon } from '../assets/warning-icon.svg';
import AppNotFoundBackground from '../assets/gradient.svg';
import { APP_NOT_FOUND_TITLE } from '../constants';

// eslint-disable-next-line max-lines-per-function
const AppNotFound = () => {
  return (
    <div
      className="flex flex-col justify-center items-center h-screen bg-[#FAFAFC]"
      style={{
        backgroundImage: `url(${AppNotFoundBackground as string})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="p-7">
        <LazyLogo width={87} height={28} />
      </div>

      <div className="flex flex-col items-center flex-1 w-full justify-end">
        <div
          className="flex flex-col items-center bg-white px-[10px] py-[30px] rounded-2xl
       h-[65vh] w-[85%] text-center gap-[30px] shadow-lg"
        >
          <div className="pt-10 pb-2">
            <WarningIcon width={40} height={40} />
          </div>
          <span id={APP_NOT_FOUND_TITLE} className="text-5xl leading-normal">
            You can’t view this app at the moment!
          </span>
          <div className="text-lg text-gray-700">
            <p>If you are the owner of this app, </p>
            <p>
              go to
              <a href="/" className="text-system-blue-6 cursor-pointer">
                &nbsp;your apps
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppNotFound;
