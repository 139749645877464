import React, { Ref, useRef } from 'react';
import classNames from 'classnames';
import { useTabStore } from '../../store/tab';
import Button from './Button';

export const TooltipApp = () => {
  const iframeRef: Ref<HTMLIFrameElement> = useRef<HTMLIFrameElement>(null);

  return (
    <div className="flex h-[180%] w-[100%] flex-col min-h-[300px] max-h-[300px]">
      <Button
        className={classNames('ml-auto mr-3 underline  text-system-blue-6')}
        onClick={() => {
          useTabStore.getState().setIsAppTipsShown(false);
        }}
      >
        Hide tips
      </Button>

      <iframe
        ref={iframeRef}
        name="lazy-tooltip-app"
        className={classNames('flex h-full w-full')}
        src="https://tooltips.app.getlazy.ai/tips?dark_mode=True"
        data-hj-allow-iframe
      ></iframe>
    </div>
  );
};
