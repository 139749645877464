import { create } from 'zustand';
import LazyAvatar from '../assets/sloth-avatar-blue.svg';
import DefaultAppIcon from '../assets/default-app-icon.svg';
import { File } from '../api/generated/models/File';
import { FileUploadFile } from '../components/VSCodeEditor/models';

const isUserDeviceOnDarkMode = () => {
  if (typeof window !== 'undefined' && window.matchMedia) {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }
  return false; // Default to light mode if window is not available
};

export interface AppStoreState {
  appImageURLMap: Map<string, string>;
  isAppPublished: boolean;
  appName: Map<string, string>;
  publishedAppVersionsMap: Map<string, boolean>;
  isTemplate: boolean;
  emptyAppFiles: FileUploadFile[];
  appFiles: File[];
  hasEmptySecrets: boolean;
  currentAppRam: number | null;
  autoUpdateTestApp: boolean;
  isDark: boolean;
  isTestingInstance: boolean;
  setTheme: (boolean) => void;
  setAppName: (appName: string) => void;
  setAppImageUrl: (imageUrl?: string) => void;
}

// eslint-disable-next-line max-lines-per-function
export const useAppStore = create<AppStoreState>((set, get) => ({
  appImageURLMap: new Map<string, string>([['lazy-app-icon', LazyAvatar as string]]),
  appName: new Map<string, string>([['lazy-app-name', 'Lazy']]),
  isAppPublished: false,
  publishedAppVersionsMap: new Map<string, boolean>(),
  isTemplate: false,
  emptyAppFiles: [],
  appFiles: [],
  hasEmptySecrets: false,
  currentAppRam: null,
  autoUpdateTestApp: true,
  isTestingInstance: false,

  isDark: localStorage.getItem('getlazyai-theme')
    ? localStorage.getItem('getlazyai-theme') === 'dark'
    : isUserDeviceOnDarkMode(),

  setTheme: (isDark: boolean) => {
    set(() => {
      const isInsideIframe = window.self !== window.top;
      if (!isInsideIframe) {
        localStorage.setItem('getlazyai-theme', isDark ? 'dark' : 'light');
      }

      return { isDark };
    });
  },

  setAppName: (appName: string) => {
    const appNameMap = get().appName;
    appNameMap.set('lazy-app-name', appName);
    set({ appName: appNameMap });
  },

  setAppImageUrl: (imageUrl?: string) => {
    const appImageURLMap = get().appImageURLMap;
    appImageURLMap.set(`lazy-app-icon`, imageUrl || (DefaultAppIcon as string));
    set({ appImageURLMap });
  },
}));
