import React from 'react';
import classNames from 'classnames';

const checkMark = () => (
  <span
    className={classNames(
      'mr-2 text-white bg-green-500 rounded-full',
      'w-4 h-4 inline-flex justify-center items-center'
    )}
  >
    ✓
  </span>
);

export const PublishCostWarning = () => {
  return (
    <div
      className="text px-3 py-2 rounded-md m-4 
     bg-white dark:text-dark-label-secondary dark:bg-dark-primary"
    >
      <div className="flex items-center gap-2">
        <span>
          Publish to Google Cloud Platform.
          <ul className="p-2">
            <li>{checkMark()} Guaranteed 24/7 app up time (99.99%)</li>
            <li>{checkMark()} Automatic database backups</li>
            <li>{checkMark()} Add a custom domain</li>
          </ul>
          <span className={classNames('text-subtitle-custom-gray text-400 ')}>
            Please note that published apps incur hosting fees (
            <button
              onClick={() =>
                window.open(
                  'https://help.getlazy.ai/en/articles/10430121-hosting-fees-billing-frequently-asked-questions',
                  '_blank'
                )
              }
              className={classNames('underline')}
            >
              Learn more
            </button>
            )
          </span>
        </span>
      </div>
    </div>
  );
};
