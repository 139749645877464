import React, { Ref, useEffect, useRef } from 'react';

import { ReactComponent as CrossIcon } from '../assets/cross-icon.svg';
import GlobalModalBackdrop from './GlobalModalBackdrop';
import useModalVisibility from '../hooks/modalVisibility';
import { AppLogic } from '../api/generated';
import AppLogicDisplay from './AppLogicDisplay';
import ActionButton from './base/ActionButton';
import HotelLineIcon from 'remixicon-react/HotelLineIcon';
import { useChatStore } from '../store/chat';
import classNames from 'classnames';

interface InfoModalProps {
  infoTitle: string;
  appLogic: AppLogic | null;
  isTemplate: boolean;
  orgName?: string;
  isAppExist: boolean;
  appLastPublishedAt: Date | null;
  appLastPublishedUrl: string | null;
  onHide: () => void;
  updateAppLogic: (newName: string, newLogic: string) => Promise<void>;
}

interface AppLogicDisplayHandles {
  onSave: () => void;
  setApprovalModalOpen: (isApprovalModalOpen: boolean) => void;
  hasUnsavedChanged: () => boolean;
}

// eslint-disable-next-line max-lines-per-function
const InfoModal = ({
  infoTitle,
  appLogic,
  isTemplate,
  orgName,
  isAppExist,
  appLastPublishedAt,
  appLastPublishedUrl,
  onHide,
  updateAppLogic,
}: InfoModalProps) => {
  const { itemRef, isVisible } = useModalVisibility(true);
  const logicDisplayRef = useRef<AppLogicDisplayHandles>(null);

  const handleSave = () => {
    if (logicDisplayRef && logicDisplayRef.current) {
      if (logicDisplayRef.current.hasUnsavedChanged()) {
        logicDisplayRef.current.onSave();
      }
      onHide();
    }
  };

  const handleCancel = () => {
    if (logicDisplayRef && logicDisplayRef.current) {
      if (logicDisplayRef.current.hasUnsavedChanged()) {
        logicDisplayRef.current.setApprovalModalOpen(true);
      } else {
        onHide();
      }
    }
  };
  useEffect(() => {
    if (!isVisible) {
      onHide();
    }
  }, [isVisible]);
  return (
    <div className={'flex items-center justify-center fixed top-0 left-0 right-0 bottom-0 z-[100]'}>
      <GlobalModalBackdrop />
      <div
        ref={itemRef as Ref<HTMLDivElement>}
        className="rounded-md bg-white shadow-2xl z-[100] h-full w-screen md:max-w-xl md:h-auto
          dark:text-dark-label-primary dark:bg-dark-primary"
      >
        <div className="flex flex-col h-full overflow-scroll">
          <div className="flex flex-row justify-between p-4 items-center">
            <div className="font-semibold text-base flex">
              {infoTitle}
              {orgName && (
                <div
                  className={classNames(
                    'flex items-center justify-center px-0.5 mx-2',
                    'bg-system-hover-blue rounded '
                  )}
                >
                  <HotelLineIcon size={22} className="text-system-blue-6 p-1" />
                  <span className="text-system-blue-6 text-md font-normal">{orgName}</span>
                </div>
              )}
            </div>
            <CrossIcon className="w-4 h-4 cursor-pointer px-1" onClick={onHide} />
          </div>
          <hr className="dark:border-dark-system-separator border-system-separator" />
          <AppLogicDisplay
            ref={logicDisplayRef}
            appLogic={appLogic}
            isTemplate={isTemplate}
            organizationName={orgName}
            isAppExist={isAppExist}
            lastPublishedAt={appLastPublishedAt}
            publishedUrl={appLastPublishedUrl}
            appIconURL={null}
            updateAppLogic={updateAppLogic}
            onHide={onHide}
          />
          <hr className="dark:border-dark-system-separator border-system-separator" />
          <div className="flex p-4 justify-end gap-2 items-end self-end">
            <ActionButton
              fillState="subtle"
              size="medium"
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </ActionButton>
            <ActionButton
              size="medium"
              onClick={() => {
                handleSave();
              }}
              disabled={useChatStore.getState().userInputLoading}
            >
              Save
            </ActionButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
