import classNames from 'classnames';
import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import { Tooltip } from 'react-tooltip';

import { LazyApp } from '@lazy-frontend/api/generated';

import PublishedIcon from '../../../../../assets/published.svg';
import ThreeDotsIcon from '../../../../../assets/three-dots.svg';
import { useAppStore } from '../../../../../store/app';
import { useAppIdFromUrl, useGoToApp } from '../../../hooks';
import { DEFAULT_NEW_APP_NAME, DRAGABLE_ITEM_TYPE } from '../../constants';
import {
  CURRENT_ACTIVE_APP_ID,
  TEST_ID_SIDEBAR_ACTIVE_APP_ACTION_BUTTON,
} from '../../../../../constants';
import { ActionsMenu } from './ActionsMenu';
import { isMobileDevice } from '../../../../../utils/deviceDimensions';

interface AppProps {
  app: LazyApp;
  onAppMoved: () => Promise<void>;
  closeSidebar: () => void;
}

// eslint-disable-next-line max-lines-per-function
const App: React.FC<AppProps> = ({ app, onAppMoved, closeSidebar }) => {
  const [{ isDragging }, dragRef] = useDrag({
    type: DRAGABLE_ITEM_TYPE,
    item: app,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const publishedAppVersionsMap = useAppStore.getState().publishedAppVersionsMap;
  const goToApp = useGoToApp();
  const appIdFromUrl = useAppIdFromUrl();
  const [showActionsMenu, setShowActionsMenu] = useState(false);

  return (
    <div
      className={classNames(
        'flex flex-1 px-2 py-1.5 w-full gap-1 items-center justify-start rounded',
        'hover:bg-neutral-200 relative pr-3 hover:dark:bg-dark-secondary',
        {
          '!bg-system-hover-green': isDragging,
          'bg-neutral-200 dark:bg-dark-tertiary': app.id === appIdFromUrl,
        }
      )}
    >
      <div
        ref={dragRef}
        className={classNames(`app-name-container-${app.id}`, 'flex gap-1 flex-1 overflow-hidden')}
        onClick={() => {
          if (isMobileDevice()) {
            closeSidebar();
          }
          localStorage.setItem(CURRENT_ACTIVE_APP_ID, app.id);
          goToApp(app.id);
        }}
      >
        <div className="w-5 h-5">
          {publishedAppVersionsMap.get(app.id) && (
            <img src={PublishedIcon as string} alt="Published Icon" />
          )}
        </div>
        <div className="label-primary text-sm truncate flex-1 dark:text-dark-label-secondary">
          {app?.name || DEFAULT_NEW_APP_NAME}
        </div>
        {app.is_template && (
          <div
            className="flex justify-center bg-indigo-50 text-indigo-600 px-1.5
                items-center font-medium rounded text-xs
              dark:text-dark-label-primary dark:bg-dark-background-input"
          >
            Template
          </div>
        )}
      </div>
      <div
        className="flex w-5 h-5 cursor-pointer"
        onClick={() => setShowActionsMenu(true)}
        data-testid={app.id === appIdFromUrl ? TEST_ID_SIDEBAR_ACTIVE_APP_ACTION_BUTTON : null}
      >
        <img src={ThreeDotsIcon as string} alt="Three Dots Icon" />
      </div>
      {showActionsMenu && (
        <ActionsMenu app={app} onAppMoved={onAppMoved} onClose={() => setShowActionsMenu(false)} />
      )}
      <Tooltip anchorSelect={`.app-name-container-${app.id}`} place="top" delayShow={500}>
        {app?.name || DEFAULT_NEW_APP_NAME}
      </Tooltip>
    </div>
  );
};

export default App;
