import React, { useState } from 'react';
import * as dataTestidConstants from '../../constants';
import { useAuthStore } from '../../store/auth';
import QuestionIcon from 'remixicon-react/QuestionLineIcon';
import { Tooltip } from 'react-tooltip';
import CheckboxCircleIcon from 'remixicon-react/CheckboxBlankCircleLineIcon';
import RecordIcon from 'remixicon-react/RecordCircleLineIcon';
import { UpgradeToProButton } from '../UpgradeToProButton';
import classNames from 'classnames';
import { isMobileDevice } from '../../utils/deviceDimensions';
import { useAppStore } from '../../store/app';

export interface AppMemoryOptionsProps {
  currentRam?: number | null;
}

// eslint-disable-next-line max-lines-per-function
export const AppMemoryOptions = (props: AppMemoryOptionsProps) => {
  const [selectedMemory, setSelectedMemory] = useState<string>(props.currentRam?.toString() || '');
  const { userPermissions } = useAuthStore();

  const Separator = () => (
    <span className="px-2">
      <div className="bg-label-tertiary rounded-full h-1 w-1" />
    </span>
  );

  const MemoryOption = (label, memory) => (
    <span className="flex items-center">
      {label} <Separator /> {memory}
      <div className="flex flex-1 items-end justify-end"></div>
    </span>
  );

  const memoryOptions = [
    { label: 'Default', value: '', showTooltip: true },
    {
      label: MemoryOption('Light', '256 MB'),
      value: '256',
      upgradeRequired: !userPermissions?.isUserAllowedToConfigureProMemory,
    },
    {
      label: MemoryOption('Medium', '512 MB'),
      value: '512',
      upgradeRequired: !userPermissions?.isUserAllowedToConfigureProMemory,
    },
    {
      label: MemoryOption('Large', '2.5 GB'),
      value: String(1024 * 2.5),
      upgradeRequired: !userPermissions?.isUserAllowedToConfigureProMemory,
    },
    {
      label: MemoryOption('Mega', '4.5 GB'),
      value: String(1024 * 4.5),
      upgradeRequired: !userPermissions?.isUserAllowedToConfigureProMemory,
    },
  ];

  const renderTooltip = () => (
    <div
      data-tooltip-content="Let Lazy choose an appropriate value"
      data-tooltip-id="default-memory-tooltip"
    >
      <QuestionIcon className="text-label-tertiary" size={20} />
      <Tooltip id="default-memory-tooltip" place="bottom" />
    </div>
  );

  const handleMemoryChange = (value: string) => {
    setSelectedMemory(value);
    useAppStore.setState({ currentAppRam: value ? Number(value) : null });
  };
  return (
    <div className="dark:bg-dark-tertiary dark:text-dark-label-primary">
      <div className="text-sm mb-2 text-label-secondary font-medium">
        How much memory do you need?
      </div>
      <div
        className="flex flex-col gap-1.5"
        data-testid={dataTestidConstants.TEST_APP_RUN_MEMORY_SELECTOR_OPTIONS}
      >
        {memoryOptions.map((option) => (
          <div
            key={option.value}
            className={classNames('flex items-center justify-between', {
              'h-6': !isMobileDevice(),
              'h-7': isMobileDevice(),
            })}
          >
            <label
              className={classNames('flex items-center font-normal', {
                'cursor-pointer': !option.upgradeRequired,
                'cursor-not-allowed': option.upgradeRequired,
              })}
              onClick={() => !option.upgradeRequired && handleMemoryChange(option.value)}
            >
              <span className="mr-2">
                {selectedMemory === option.value ? (
                  <RecordIcon className="text-system-accent" size={20} />
                ) : (
                  <CheckboxCircleIcon className="text-label-tertiary" size={20} />
                )}
              </span>
              {option.label}
            </label>
            {option.showTooltip && renderTooltip()}
            {option.upgradeRequired && (
              <UpgradeToProButton
                customButtonText="Upgrade"
                hideIcon
                customStyles="!bg-purple-100 !text-purple-600 !p-1 !px-2 h-auto text-xs"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
