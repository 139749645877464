import React from 'react';
import classNames from 'classnames';
import MoonFill from 'remixicon-react/MoonFillIcon';
import SunFill from 'remixicon-react/SunFillIcon';
import { useAppStore } from '../../store/app';

export default function ThemeToggle() {
  const { isDark, setTheme } = useAppStore();
  const isInsideIframe = window.self !== window.top;

  if (isInsideIframe) {
    return null;
  }

  return (
    <button
      onClick={() => setTheme(!isDark)}
      className={classNames(
        'p-2 rounded-full bg-gray-200 dark:bg-gray-800',
        ' hover:bg-gray-300 dark:hover:bg-gray-700 transition'
      )}
    >
      {isDark ? (
        <SunFill className="w-4 h-4 text-yellow-400" />
      ) : (
        <MoonFill className="w-4 h-4 text-gray-800 dark:text-gray-200" />
      )}
    </button>
  );
}
