import React, { memo } from 'react';
import Button, { ButtonIcon } from './Button';
import { useChatStore } from '../../store/chat';

interface ButtonProps {
  text: string;
  className?: string;
  dataTestId?: string;
  iconProps?: ButtonIcon;
}

export interface ApproveModalProps {
  message: React.ReactNode | string;
  onApprove: () => void;
  onReject?: () => void;
  approveButton?: ButtonProps;
  rejectButton?: ButtonProps;
}

export const ApproveModal: React.FC<ApproveModalProps> = memo(
  // eslint-disable-next-line max-lines-per-function
  ({
    message,
    onApprove,
    onReject,
    // Set default button configurations here
    approveButton = {
      text: 'Save changes',
      className: 'bg-system-success text-white',
    },
    rejectButton = {
      text: "Don't save",
      className: 'bg-white text-label-secondary border border-colors-neutral-300 ',
    },
  }) => {
    const handleApprove = () => {
      onApprove();
    };

    const handleReject = () => {
      onReject && onReject();
    };

    return (
      <>
        <div className="bg-lazy-light-grey absolute top-0 left-0 right-0 bottom-0 z-10"></div>
        <div
          className="rounded-lg bg-lazy-light-grey fixed flex-1 top-0 left-0
             flex items-center justify-center h-screen w-screen z-[30]"
        >
          <div
            className="flex flex-col items-center justify-center p-6
            dark:text-dark-label-primary dark:bg-dark-primary
            gap-6 z-40 bg-white rounded max-w-80"
          >
            <div className="w-full text-center text-sm font-medium">{message}</div>
            <div className="w-full flex flex-col gap-2">
              <Button
                className={approveButton.className}
                dataTestid={approveButton.dataTestId}
                iconProps={approveButton.iconProps}
                onClick={handleApprove}
                disabled={useChatStore.getState().userInputLoading}
              >
                {approveButton.text}
              </Button>
              <Button
                className={rejectButton.className}
                dataTestid={rejectButton.dataTestId}
                iconProps={rejectButton.iconProps}
                onClick={handleReject}
              >
                {rejectButton.text}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
);
