import React from 'react';
import ArrowRightDownIcon from 'remixicon-react/ArrowRightDownLineIcon';
import { StarterPrompt as StarterPromptType } from '../../../api/generated';
import ActionButton from '../../base/ActionButton';

interface StarterPromptProps {
  starterPrompt: StarterPromptType;
  submitStarterPrompt: (starterPrompt: string) => void;
}

const StarterPrompt = (props: StarterPromptProps) => {
  return (
    <ActionButton
      className="w-full !justify-between py-2 text-sm font-medium leading-5
       !h-10 text-left border border-system-blue-6"
      buttonType="primary"
      fillState="subtle"
      onClick={() => props.submitStarterPrompt(props.starterPrompt.prompt)}
    >
      <span>{props.starterPrompt.label || props.starterPrompt.prompt}</span>
      <span>
        <ArrowRightDownIcon size={20} className="flex-1" />
      </span>
    </ActionButton>
  );
};

export default StarterPrompt;
