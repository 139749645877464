import React from 'react';
import Typewriter from 'typewriter-effect';
import Loader4LineIcon from 'remixicon-react/Loader4LineIcon';
import { SHUFFLED_LOADING_MESSAGES } from '../../i18n';
import classNames from 'classnames';
import { TEST_ID_CHAT_STOP_BUTTON, TEST_ID_LOADING_BAR } from '../../constants';

interface RichPromptInputLoaderProps {
  onStop: () => void;
  hideStop: boolean;
  message?: string | null;
}

// eslint-disable-next-line max-lines-per-function
const RichPromptInputLoader = (props: RichPromptInputLoaderProps) => {
  const hasFixedMessage = props.message !== null && props.message !== undefined;
  return (
    // eslint-disable-next-line max-len
    <div
      className="flex w-full items-center flex-row border rounded-lg shadow-lg
     bg-gray-800 text-white p-2 border-system-separator dark:border-dark-system-separator"
    >
      <Loader4LineIcon className="animate-spin mr-3" />
      <div
        className="whitespace-pre-line overflow-hidden"
        data-testid={TEST_ID_LOADING_BAR}
        data-message-contents={hasFixedMessage ? props.message : 'random-messages'}
      >
        {hasFixedMessage ? (
          <Typewriter
            key={props.message}
            onInit={(typewriter) => {
              typewriter.typeString(props.message || '').start();
            }}
            options={{
              delay: 75,
            }}
          />
        ) : (
          <Typewriter
            options={{
              strings: SHUFFLED_LOADING_MESSAGES,
              autoStart: true,
              delay: 75,
              deleteSpeed: 15,
              loop: true,
            }}
          />
        )}
      </div>
      <button
        onClick={(_e) => props.onStop()}
        className={classNames('border border-gray-500 rounded-md p-1 text-xs ml-auto', {
          hidden: props.hideStop,
        })}
        data-testid={TEST_ID_CHAT_STOP_BUTTON}
      >
        Stop
      </button>
    </div>
  );
};

export default RichPromptInputLoader;
