import React from 'react';
import classNames from 'classnames';
import { UpgradeToProButton } from './UpgradeToProButton';
import { isMobileDevice } from '../utils/deviceDimensions';

export interface UpgradeToProNoticeProps {
  optionText?: string;
}

export const UpgradeToProNotice = ({ optionText }: UpgradeToProNoticeProps) => {
  return (
    <div
      className={classNames(
        'w-full h-full flex flex-1 items-center justify-center',
        ' bg-background-secondary dark:bg-dark-tertiary'
      )}
    >
      <div
        className={classNames('flex gap-4 flex-col p-3 items-center', {
          'gap-3 p-2': isMobileDevice(),
        })}
      >
        <div
          className="font-semibold items-center text-center max-w-[220px] text-xl
        dark:text-dark-label-primary"
        >
          {optionText || 'You need to upgrade to run your app'}
        </div>

        <UpgradeToProButton customButtonText="Upgrade plan" />
      </div>
    </div>
  );
};
