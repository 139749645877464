import React from 'react';
import Button from './Button';
import classNames from 'classnames';
import { isMobileDevice } from '../../utils/deviceDimensions';

export interface TabBarItemProps {
  extraClasses?: string[];
}

export interface TabBarItem {
  contents: React.ReactNode;
  onClick?: () => void;
  props?: TabBarItemProps;
  disabled?: boolean;
  hidden?: boolean;
  dataTestid?: string;
}
export interface TabBarProps {
  items: TabBarItem[];
  onSelect?: (item: TabBarItem, index: number) => void;
  selectedIndex?: number;
  disabled?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const TabBar = ({ items, selectedIndex, onSelect, disabled }: TabBarProps) => {
  const isMobileView = isMobileDevice();
  const onClickItem = (item: TabBarItem, index: number) => {
    if (item.disabled) {
      return;
    }

    if (item.onClick) {
      item.onClick();
    }

    if (onSelect) {
      onSelect(item, index);
    }
  };

  return (
    <div
      className={classNames(
        'flex justify-center items-center gap-1 bg-background-secondary',
        'rounded-md h-[34px] p-0.5 dark:bg-dark-tertiary',
        {
          'w-full': isMobileDevice(),
        }
      )}
    >
      {items
        .filter((item) => !item.hidden)
        .map((item, index) => (
          <Button
            key={index}
            dataTestid={item.dataTestid}
            onClick={() => onClickItem(item, index)}
            className={classNames(
              'h-[30px] w-[135px]',
              {
                // eslint-disable-next-line
                'bg-white hover:bg-white dark:bg-dark-background-input dark:text-dark-label-primary':
                  index === selectedIndex && !item.disabled,
                'bg-transparent text-lazy-light-grey dark:text-dark-label-primary':
                  index !== selectedIndex || item.disabled,
                'hover:bg-transparent cursor-not-allowed': item.disabled,
                'w-full': isMobileView,
                'hover:text-label-primary': !item.disabled,
                disabled: item.disabled || disabled,
                'shadow-sm': !isMobileView && index === selectedIndex && !item.disabled,
              },
              item.props?.extraClasses?.join(' ')
            )}
          >
            {item.contents}
          </Button>
        ))}
    </div>
  );
};

export default TabBar;
