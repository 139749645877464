import React from 'react';
import ReactMarkdown from 'react-markdown';
import Linkify from 'react-linkify';

import { TextMessageContentFormat } from '../../../types';
import classNames from 'classnames';

interface TextMessageProps {
  text: string;
  format: TextMessageContentFormat;
}

// eslint-disable-next-line max-lines-per-function
const TextMessage = ({ text, format }: TextMessageProps) => {
  const urlDecorator = (href: string, text: string, key: number) => {
    const isImageLink = /\.(png|jpg|jpeg|gif|bmp|svg)/i.test(text);
    const isFile = /\.(py|js|txt)/.test(text);
    const isLocalHostHref = /http(s)?:\/\/\d{1,}\.\d{1,}\.\d{1,}\.\d{1,}:\d+/.test(href);
    if (isFile) {
      return text;
    } else if (isLocalHostHref || isImageLink) {
      return text;
    } else {
      return (
        <a href={href} target="_blank" key={key} className="text-lazy-action">
          {text}
        </a>
      );
    }
  };

  switch (format) {
    case TextMessageContentFormat.Markdown:
      return (
        <ReactMarkdown
          className={classNames(
            'chatMessageMarkdown text-label-primary dark:!text-dark-label-primary'
          )}
        >
          {text}
        </ReactMarkdown>
      );
    case TextMessageContentFormat.Plain:
      return (
        <span>
          <Linkify componentDecorator={urlDecorator}>{text}</Linkify>
        </span>
      );
  }
};

export default TextMessage;
