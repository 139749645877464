import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import WindowLineIcon from 'remixicon-react/WindowLineIcon';
import ArrowLeftLineIcon from 'remixicon-react/ArrowLeftLineIcon';
import ArrowRightLineIcon from 'remixicon-react/ArrowRightLineIcon';
import LinkIcon from 'remixicon-react/LinkIcon';
import MagicLineIcon from 'remixicon-react/MagicLineIcon';
import * as dataTestidConstants from '../../constants';
import { FrontendEvents } from '../../api/StatsApi';
import { copyToClipboard } from '../../utils/clipboard';
import { ReactComponent as LoopLeftIcon } from '../../assets/loop-left.svg';
import { Loader } from './Loader';
import { toast } from 'react-toastify';
import LaptopIcon from 'remixicon-react/MacbookLineIcon';
import PhoneIcon from 'remixicon-react/SmartphoneLineIcon';
import ActionButton from './ActionButton';
import { isMobileDevice } from '../../utils/deviceDimensions';
import { useAuthStore } from '../../store/auth';
import { UpgradeToProNotice } from '../../components/UpgradeToProNotice';
import CloseIcon from 'remixicon-react/CloseLineIcon';
import GlobalModalBackdrop from '../../components/GlobalModalBackdrop';

const DISABLE_NAVIGATION_BUTTONS = true;

export interface BrowserFrameProps {
  testing: boolean;
  url: string;
  displayIframe: boolean;
  emitEvent: (eventType: FrontendEvents) => void;
}

// eslint-disable-next-line max-lines-per-function
export const BrowserFrame = ({ url, displayIframe, emitEvent, testing }: BrowserFrameProps) => {
  const [isResponsiveIFrame, setIsResponsiveIFrame] = useState<boolean>(false);
  const [showUpgradeToProNotice, setShowUpgradeToProNotice] = useState<boolean>(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { userPermissions } = useAuthStore();

  const handleReload = () => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      iframe.src = 'about:blank'; // Set src to a blank page
      iframe.src = url; // Set src back to the original URL
    }
  };

  const handleNext = () => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      iframe.contentWindow?.history.forward(); // Navigate forward in iframe history
    }
  };

  const handleBack = () => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      iframe.contentWindow?.history.back(); // Navigate back in iframe history
    }
  };

  const renderIframePlaceholder = () => <Loader fullScreen={false} />;

  const renderIframe = () => (
    <iframe
      key={isResponsiveIFrame ? 'responsive' : 'full-width'}
      ref={iframeRef}
      name="lazy-app-run-iframe"
      src={url}
      className={classNames('rounded-b-lg h-full', {
        'w-[390px] max-h-[844px] ml-auto mr-auto': isResponsiveIFrame,
        'w-full': !isResponsiveIFrame,
      })}
      data-hj-allow-iframe
    ></iframe>
  );

  const renderUpgradeToProNotice = () => (
    <>
      <GlobalModalBackdrop disableClickToCancelModal={true} />
      <div
        className={'flex items-center justify-center fixed top-0 left-0 right-0 bottom-0 z-[100]'}
      >
        <div className="bg-background-secondary dark:bg-dark-tertiary rounded-lg p-4 w-1/2">
          <div className="flex justify-end">
            <CloseIcon
              className="cursor-pointer dark:text-dark-label-primary"
              onClick={() => setShowUpgradeToProNotice(false)}
            />
          </div>
          <UpgradeToProNotice optionText="You need to upgrade to edit the app" />
        </div>
      </div>
    </>
  );

  return (
    <div className={'flex flex-col h-full w-full'}>
      {showUpgradeToProNotice && renderUpgradeToProNotice()}
      <div
        className={classNames(
          'bg-white flex items-center p-2 gap-1',
          'box-border rounded-t-lg border-b border-gray-300',
          'dark:bg-dark-background-input dark:text-dark-label-primary'
        )}
      >
        {!DISABLE_NAVIGATION_BUTTONS && (
          <div className="flex gap-1 px-1">
            <div className="rounded-full border border-system-separator p-1" onClick={handleBack}>
              <ArrowLeftLineIcon size={18} color="label-primary" />
            </div>
            <div className="rounded-full border border-system-separator p-1" onClick={handleNext}>
              <ArrowRightLineIcon size={18} color="label-primary" />
            </div>
          </div>
        )}
        <div
          className={classNames(
            'bg-white h-7 rounded-full flex items-center text-sm text-gray-600 border-gray-300',
            'flex-grow px-4 whitespace-nowrap overflow-hidden overflow-ellipsis',
            'border border-solid dark:bg-dark-background-input dark:text-dark-label-primary',
            'max-w-[450px]'
          )}
          aria-hidden
        >
          <div className="flex flex-1 items-center overflow-hidden">
            <span className="overflow-hidden whitespace-nowrap overflow-ellipsis">{url}</span>
          </div>
          <div className="cursor-pointer" onClick={handleReload}>
            <LoopLeftIcon
              className="h-4 w-4 pointer-events-none 
              text-label-secondary dark:text-dark-label-primary"
            />
          </div>
        </div>
        <div className="flex gap-3 min-w-[235px] flex-1 justify-start">
          <ActionButton
            buttonType="primary"
            fillState="subtle"
            onClick={() => {
              copyToClipboard(url);
              toast.success('Link copied successfully.');
            }}
          >
            <LinkIcon size={20} />
            Copy link
          </ActionButton>
          <ActionButton
            buttonType="primary"
            fillState="subtle"
            onClick={() => {
              emitEvent(FrontendEvents.USER_CLICKED_OPEN_APP_BUTTON);
              window.open(url, '_blank');
            }}
            dataTestid={dataTestidConstants.TEST_APP_OPEN_APP_BUTTON as string}
          >
            <WindowLineIcon size={20} />
            Open in tab
          </ActionButton>
        </div>
        <div className="flex flex-row h-[34px]">
          <ActionButton
            buttonType="primary"
            fillState="light"
            className={classNames(
              'py-1 pl-3 pr-4 text-sm font-medium',
              'rounded-tr-none rounded-br-none',
              {
                '!bg-system-accent text-white dark:hover:!text-dark-label-primary':
                  !isResponsiveIFrame,
                'bg-neutral-200 text-gray-700': isResponsiveIFrame,
              }
            )}
            onClick={() => {
              setIsResponsiveIFrame(false);
            }}
          >
            <LaptopIcon size={16} />
          </ActionButton>
          <ActionButton
            buttonType="primary"
            fillState="light"
            className={classNames(
              'py-1 pl-3 pr-4 text-sm font-medium',
              'rounded-tl-none rounded-bl-none',
              {
                '!bg-system-accent text-white dark:hover:!text-dark-label-primary':
                  isResponsiveIFrame,
                'bg-neutral-200 text-gray-700': !isResponsiveIFrame,
              }
            )}
            onClick={() => {
              setIsResponsiveIFrame(true);
            }}
          >
            <PhoneIcon size={16} />
          </ActionButton>
          {!isMobileDevice() && testing && (
            <ActionButton
              buttonType="primary"
              fillState="filled"
              className="!bg-purple-500 text-white font-semibold ml-2"
              onClick={() => {
                if (userPermissions?.isUserAllowedToPrompt) {
                  iframeRef.current?.contentWindow?.postMessage(
                    { action: 'ENABLE_SELECTION_MODE' },
                    '*'
                  );
                } else {
                  setShowUpgradeToProNotice(true);
                }
              }}
            >
              <MagicLineIcon size={20} />
              Point & edit
            </ActionButton>
          )}
        </div>
      </div>
      <div className={classNames('flex flex-1 h-full w-full bg-white rounded-b-lg relative')}>
        {displayIframe ? renderIframe() : renderIframePlaceholder()}
      </div>
    </div>
  );
};
