import React, { useEffect, useState, useContext } from 'react';
import { useChatStore } from '../../store/chat';
import { LazyApp, StarterPrompt } from '../../api/generated';
import MessageList from './MessageList';
import classNames from 'classnames';
import { MessageSourceType, MessageType, TextMessageContentFormat, Message } from '../../types';
import LazyAvatarError from './RichPrompt.avatar-lazy-error.png';
import LazyAvatar from '../../assets/sloth-avatar-blue.svg';
import { AppRunContext } from '../../pages/AppRun';
import { TabBarItemsIndex } from '../../constants';
import { TooltipApp } from '../base/TooltipAppIFrame';
import { useTabStore } from '../../store/tab';
import { BuilderInteractionMode } from '../../api/generated/models/BuilderInteractionMode';

interface MessageListRendererProps {
  app?: LazyApp | null;
  submitStarterPrompt?: (starterPrompt: string) => void;
  mainContainerElementRef?: React.RefObject<HTMLDivElement>;
  scrollDownButtonPb?: number;
  handleChatContainerScrolling?: () => void;
  onPublishVersion?: (string) => void;
  isAllPreviousMessagesLoaded: boolean;
  loadMoreMessages: () => Promise<void>;
  selectedTabIndex: React.Dispatch<React.SetStateAction<TabBarItemsIndex>>;
  updateFiles?: (updatedFiles: File[]) => Promise<void>;
  activeBuilderSessionStateId?: string | null;
  isAppRun?: boolean;
}

const CONNECTING_WITH_SERVER_LOGS_MESSAGE = 'Connecting to server logs...';

// eslint-disable-next-line max-lines-per-function
const MessageListRenderer = (props: MessageListRendererProps) => {
  const {
    messages: chatMessages,
    error,
    revertFunction,
    viewVersionFunction,
    testVersionFunction,
    currentUserPromptIsEmpty,
  } = useChatStore();

  const [messages, setMessages] = useState<Message[]>([]);
  const [starterPrompts, setStarterPrompts] = useState<StarterPrompt[] | undefined>();
  const [showExploreTemplates, setShowExploreTemplates] = useState<boolean | undefined>();
  const { isRunning } = useContext(AppRunContext);
  const [showAppTips, setShowAppTips] = useState<boolean>(true);
  const isChatLoading = useChatStore((state) => state.userInputLoading);
  const currentBuilderInteractionMode = useChatStore(
    (state) => state.currentBuilderInteractionMode
  );

  const {
    isUserSendingPrompt,
    starterPrompts: starterPromptsFromStore,
    hasSoundsGoodButton,
  } = useChatStore();
  const isAppTipsShown = useTabStore((state) => state.isAppTipsShown);

  // eslint-disable-next-line max-lines-per-function, max-statements
  useEffect(() => {
    const starterPrompts: StarterPrompt[] | undefined = starterPromptsFromStore;

    const userMessages = chatMessages.filter(
      (message) => message.source.type === MessageSourceType.User
    );
    const starterPromptsAndNoUserMessages = starterPrompts && userMessages.length === 0;

    if (starterPromptsAndNoUserMessages) {
      setShowExploreTemplates(true);
    } else {
      setShowExploreTemplates(false);
    }

    if (starterPromptsAndNoUserMessages && !hasSoundsGoodButton && !isUserSendingPrompt) {
      if (!currentUserPromptIsEmpty) {
        setStarterPrompts(undefined);
      } else {
        setStarterPrompts(starterPrompts);
      }
    } else {
      setStarterPrompts(undefined);
    }

    // Filter out messages that have starter prompts but no text content
    let chatMessagesToRender = chatMessages.filter((message) => {
      const hasStarterPrompts = message.starterPrompts && message.starterPrompts.length;
      const hasText = message.content.type === MessageType.Text && message.content.text;
      return !(hasStarterPrompts && !hasText);
    });

    if (isRunning && !error && chatMessages.length === 0) {
      chatMessagesToRender = [
        {
          content: {
            type: MessageType.Text,
            text: CONNECTING_WITH_SERVER_LOGS_MESSAGE,
            format: TextMessageContentFormat.Plain,
          },
          source: {
            type: MessageSourceType.System,
            avatarUrl: LazyAvatar as string,
            name: 'Lazy',
          },
        },
      ];
    }

    if (error) {
      setMessages([
        ...chatMessagesToRender,
        {
          content: {
            type: MessageType.Text,
            text: error,
            format: TextMessageContentFormat.Plain,
          },
          source: {
            type: MessageSourceType.System,
            avatarUrl: LazyAvatarError as string,
            name: 'Lazy',
          },
        },
      ]);
    } else {
      setMessages(chatMessagesToRender);
    }
  }, [
    chatMessages,
    error,
    currentUserPromptIsEmpty,
    isRunning,
    starterPromptsFromStore,
    isUserSendingPrompt,
  ]);

  useEffect(() => {
    const isPromptStartsWithEng = useChatStore.getState().currentUserPrompt.startsWith('@eng');
    const isChatFromTryToFixIt = useChatStore
      .getState()
      .currentUserPrompt.startsWith('I got the following exception');
    setShowAppTips(
      !props.isAppRun &&
        isAppTipsShown &&
        isUserSendingPrompt &&
        isChatLoading &&
        (currentBuilderInteractionMode === BuilderInteractionMode.BUILD ||
          isPromptStartsWithEng ||
          isChatFromTryToFixIt)
    );
  }, [
    props.isAppRun,
    isAppTipsShown,
    isChatLoading,
    currentBuilderInteractionMode,
    isUserSendingPrompt,
    hasSoundsGoodButton,
  ]);

  return (
    <>
      <div
        className={classNames(
          'flex min-h-0 grow flex-col justify-end flex-nowrap h-full overflow-y-auto',
          'dark:text-dark-label-primary dark:bg-dark-primary',
          {
            hidden: useChatStore.getState().hideChatMessages,
          }
        )}
      >
        <MessageList
          starterPrompts={starterPrompts}
          submitStarterPrompt={props.submitStarterPrompt}
          showExploreTemplates={showExploreTemplates}
          isTemplate={props.app?.is_template}
          messages={messages}
          revertFunction={revertFunction}
          viewAppVersionFunction={viewVersionFunction}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          testVersionFunction={testVersionFunction}
          loadMoreMessages={props.loadMoreMessages}
          isAllPreviousMessagesLoaded={props.isAllPreviousMessagesLoaded}
          onPublishVersion={props.onPublishVersion}
          mainContainerElementRef={props.mainContainerElementRef}
          scrollDownButtonPb={props.scrollDownButtonPb}
          handleChatContainerScrolling={props.handleChatContainerScrolling}
          activeBuilderSessionStateId={props.activeBuilderSessionStateId}
          selectedTabIndex={props.selectedTabIndex}
          updateFiles={props.updateFiles}
          isAppRun={props.isAppRun}
          isUntitledApp={props.app?.name?.toLowerCase() === 'untitled app'}
        />
      </div>

      {showAppTips && <TooltipApp />}
    </>
  );
};

export default MessageListRenderer;
