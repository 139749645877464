import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import QuestionIcon from 'remixicon-react/QuestionLineIcon';
import RocketIcon from 'remixicon-react/Rocket2LineIcon';

import { getInstanceTTLInfo } from '../api/ClientApi';
import { ActionForParentWindow } from '../types';
import { isMobileDevice } from '../utils/deviceDimensions';
import { ApproveModal } from './base/ApproveModal';
import * as dataTestidConstants from '../constants';
import Button from './base/Button';

interface AppAutoShutDownTimerProps {
  instanceId: string;
}

const requestParentWindowToOpenPublishModal = () => {
  const action: ActionForParentWindow = {
    openPublishModal: true,
  };
  window.parent.postMessage(action, '*');
};

// eslint-disable-next-line max-lines-per-function
export const AppAutoShutDownTimer: React.FC<AppAutoShutDownTimerProps> = ({ instanceId }) => {
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const [showInfoModal, setShowInfoModal] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const fetchTTL = async () => {
      try {
        const response = await getInstanceTTLInfo(instanceId);
        if (response && isMounted) {
          setTimeLeft(response.ttl_of_instance);
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error('Error fetching TTL:', error);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchTTL();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime !== null && prevTime > 0) {
          return prevTime - 1;
        }
        return prevTime;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft !== null]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const renderInfoModal = () => {
    return (
      <ApproveModal
        message={
          'This is a test server for your app, if you want a persistent ' +
          'version of your app, publish a production version'
        }
        onApprove={() => {
          setShowInfoModal(false);
          requestParentWindowToOpenPublishModal();
        }}
        onReject={() => {
          setShowInfoModal(false);
        }}
        approveButton={{
          text: 'Publish latest version',
          className: 'bg-system-accent text-white',
          iconProps: { icon: RocketIcon, iconSize: 18 },
        }}
        rejectButton={{
          text: 'I’ll Publish later',
          className: 'bg-white text-label-secondary',
        }}
      />
    );
  };

  if (!timeLeft) {
    return;
  }

  return (
    <div className="flex items-center gap-1 text-label-primary dark:text-dark-label-primary">
      {showInfoModal && renderInfoModal()}
      <span className="text-label-secondary">•</span>
      {!isMobileDevice() && (
        <span data-testid={dataTestidConstants.TEST_APP_AUTO_SHUTDOWN_TIMER}>
          Test server shuts down in
        </span>
      )}
      <span
        className={classNames(
          { 'w-[35px]': timeLeft >= 60, 'w-[50px]': timeLeft < 60 },
          'text-system-danger'
        )}
      >
        {timeLeft < 60 ? '< 1 min' : formatTime(timeLeft)}
      </span>
      <QuestionIcon
        className={classNames('text-system-danger mr-3')}
        size={16}
        onClick={() => requestParentWindowToOpenPublishModal()}
      />

      {!isMobileDevice() && (
        <Button
          className="!bg-purple-500 text-white !hover:bg-purple-500/80 pr-3"
          onClick={requestParentWindowToOpenPublishModal}
        >
          <RocketIcon size={20} />
          Publish
        </Button>
      )}
    </div>
  );
};
