import React, { ChangeEvent, FC, ReactNode, memo, KeyboardEvent } from 'react';
import classNames from 'classnames';

interface InputProps {
  disabled?: boolean;
  id?: string;
  value?: string;
  defaultValue?: string;
  label?: string | ReactNode;
  type?: string;
  placeholder?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (e) => void;
  onBlur?: (e) => void;
  visible?: boolean;
  required?: boolean;
  isEditMode?: boolean;
  containerClassName?: string;
  inputClassName?: string;
  maxLength?: number;
  dataTestid?: string;
}

export const Input: FC<InputProps> = memo(
  // eslint-disable-next-line max-lines-per-function
  ({
    value = '',
    defaultValue,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    label,
    type,
    placeholder,
    id,
    isEditMode = true,
    required,
    disabled,
    inputClassName,
    containerClassName,
    maxLength,
    dataTestid,
  }) => {
    return (
      <div
        className={classNames(
          'w-full',
          { 'grid lg:gap-3 lg:grid-cols-[100px_1fr] items-center': label },
          containerClassName,
          {
            hidden: !value && !isEditMode,
          }
        )}
      >
        {label && (
          <span className={classNames('text-xs text-label-secondary font-medium')}>{label}</span>
        )}
        <input
          id={id}
          maxLength={maxLength}
          className={classNames('p-2 border dark:border-0 focus:outline-0 w-full', inputClassName, {
            'border-none bg-transparent': !isEditMode,
            // eslint-disable-next-line max-len
            'border-system-separator rounded-md placeholder:text-label-tertiary disabled:text-label-tertiary dark:text-dark-label-primary dark:bg-dark-background-input':
              isEditMode,
          })}
          type={type}
          placeholder={placeholder}
          disabled={disabled || !isEditMode}
          value={value || ''}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          required={required}
          onBlur={onBlur}
          defaultValue={defaultValue}
          data-testid={dataTestid}
        />
      </div>
    );
  }
);
